import React, { ReactElement, useCallback } from 'react';
import {
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import { useLongPress } from 'use-long-press';
import { TransactionModel } from '../../models';
import { getAvatarInitials } from '../../../utils/avatar';
import { Amount } from '../../../components/Amount';
import { useSelectState } from '../../../components/MultiSelectList/MultiSelectListContext';
import { SelectableAvatar } from '../../../components/SelectableAvatar';

interface TransactionItemProps extends TransactionModel {
  onEdit: (id: TransactionModel['id']) => void;
}

export const TransactionItem = ({
  id,
  description,
  account: { currency },
  amount,
  category,
  onEdit,
}: TransactionItemProps): ReactElement => {
  const [selected, setSelected] = useSelectState(id);
  const toggleSelected = useCallback(() => setSelected(), [setSelected]);
  const handleEdit = useCallback(
    ({ target }) => {
      if (target.closest('.list-item-select')) {
        return;
      }
      onEdit(id);
    },
    [id, onEdit],
  );
  const bind = useLongPress(() => toggleSelected(), { onCancel: handleEdit, captureEvent: true });
  return (
    <ListItemButton dense key={id} selected={selected} {...bind}>
      <ListItemAvatar>
        <SelectableAvatar
          selected={selected}
          onToggleSelect={toggleSelected}
          color={category.color}
          className="list-item-select"
        >
          {getAvatarInitials(category.name)}
        </SelectableAvatar>
      </ListItemAvatar>
      <ListItemText primary={description} secondary={category.name} />
      <ListItemSecondaryAction>
        <Amount currency={currency} value={amount} />
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};
