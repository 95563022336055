import { ReactElement } from 'react';
import { gql, useMutation } from '@apollo/client';
import { mainColors } from '../../../components/forms';
import { CategoryForm } from '../CategoryForm';
import { CategoryFormValues } from '../../models';

const CREATE_CATEGORY = gql`
  mutation AddCategory($color: String, $name: String, $parentId: uuid) {
    insert_category_one(object: { color: $color, name: $name, parent_id: $parentId }) {
      __typename
      id
      color
      name
      parent_id
    }
  }
`;

interface CategoryDialogProps {
  onClose: () => void;
}

const getInitialValues = () => ({
  name: '',
  color: mainColors[4],
  parentId: null,
});

export const CreateCategoryDialog = ({ onClose }: CategoryDialogProps): ReactElement | null => {
  const [createCategory, { loading: isCategorySaving }] = useMutation(CREATE_CATEGORY, {
    onCompleted: () => {
      onClose();
    },
  });

  const handleSubmit = (input: CategoryFormValues) => {
    const variables: CategoryFormValues = {
      ...input,
      parentId: input.parentId || null,
    };
    return createCategory({ variables });
  };

  return (
    <CategoryForm
      title="Create Category"
      description="Define a group of similar transactions"
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={isCategorySaving}
      initialValues={getInitialValues()}
    />
  );
};
