import React, { ReactElement, useMemo, useState } from 'react';
import { Box, Button, Popover, Stack, Typography } from '@mui/material';
import MoveIcon from '@mui/icons-material/DriveFileMoveOutlined';
import SaveIcon from '@mui/icons-material/DoneAll';
import { CategoryModel } from '../../../categories/models';
import { useCategories } from '../../../categories/hooks';
import { AutocompleteOption } from '../../../components/forms/Autocomplete/interfaces';
import { CategoryInput } from '../../../categories/components/CategoryInput';
import { AutocompleteControl } from '../../../components/forms/Autocomplete';
import { ConditionalTooltip } from '../../../components/ConditionalTooltip';

interface Props {
  onSubmit: (id: CategoryModel['id'] | null) => void;
  disabled?: boolean;
}

export const AssignCategoryAction = ({ onSubmit, disabled }: Props): ReactElement => {
  const { data: { categories = [] } = {} } = useCategories({ fetchPolicy: 'cache-only' });
  const options: AutocompleteOption[] = useMemo(
    () => categories.map(({ id, name: label }) => ({ id, label })),
    [categories],
  );
  const [selected, setSelected] = useState<AutocompleteOption | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (next: AutocompleteOption | null) => {
    setSelected(next);
  };

  const handleSubmit = () => {
    onSubmit(selected && selected.id);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'assign-category-action' : undefined;

  return (
    <>
      <ConditionalTooltip
        text="Select rows in the table below to enable this action"
        condition={disabled}
      >
        <Button
          aria-describedby={id}
          variant="text"
          onClick={handleClick}
          startIcon={<MoveIcon />}
          disabled={disabled}
          sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}
        >
          Assign category
        </Button>
      </ConditionalTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box padding={2} width={400}>
          <Typography color="text.secondary" fontWeight="bold" variant="body2">
            Assign category to selected transactions
          </Typography>
          <Stack direction="column" alignItems="flex-end" gap={1}>
            <CategoryInput
              label="Category"
              name="category"
              component={AutocompleteControl}
              options={options}
              onChange={handleChange}
              value={selected}
            />
            <Button
              type="submit"
              startIcon={<SaveIcon />}
              variant="text"
              onClick={handleSubmit}
              disabled={disabled}
            >
              Assign
            </Button>
          </Stack>
        </Box>
      </Popover>
    </>
  );
};
