import { ReactElement, ReactNode } from 'react';
import { Card, CardContent, CardHeader } from '@mui/material';

interface Props {
  title: string;
  children: ReactNode;
}
export const DashboardWidget = ({ title, children }: Props): ReactElement => (
  <Card variant="outlined">
    <CardHeader title={title} />
    <CardContent sx={{ px: 0, py: 0 }}>{children}</CardContent>
  </Card>
);
