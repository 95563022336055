import { motion } from 'framer-motion';
import { PropsWithChildren, ReactElement } from 'react';
import { ClickAwayListener, Paper, Theme } from '@mui/material';
import { SxProps } from '@mui/system';

interface Props {
  id: string;
  onClose: () => void;
  sx?: SxProps<Theme>;
}

export const ExpandedMenu = ({
  id,
  children,
  onClose,
  sx,
}: PropsWithChildren<Props>): ReactElement => (
  <ClickAwayListener onClickAway={onClose}>
    <Paper
      component={motion.div}
      layoutId={id}
      sx={{ width: '300px', position: 'absolute', ...sx }}
    >
      {children}
    </Paper>
  </ClickAwayListener>
);
