import { Fab, styled } from '@mui/material';
import { ReactElement } from 'react';
import { FabConfig } from '../interfaces';

export const Faberge = styled(Fab)(({ theme }) => ({
  position: 'absolute',
  bottom: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(2)})`,
  right: theme.spacing(2),
}));

export const ExtendedFaberge = ({ icon, label, ...fabProps }: FabConfig): ReactElement => (
  <Fab
    variant="extended"
    aria-label={label}
    {...fabProps}
    sx={{
      bgcolor: 'common.white',
      color: 'primary.main',
      '&:hover': {
        bgcolor: 'grey.100',
      },
      boxShadow: 1,
      ...fabProps.sx,
    }}
  >
    {icon} {label}
  </Fab>
);
