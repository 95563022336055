import React from 'react';
import MuiTextField from '@mui/material/TextField';
import { useField } from 'formik';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField/TextField';

interface TextFieldProps extends Omit<MuiTextFieldProps, 'name'> {
  name: string;
}

export const TextField: React.FC<TextFieldProps> = React.memo(({ name, ...otherProps }) => {
  const [field, { touched, error }] = useField(name);

  return (
    <MuiTextField
      {...otherProps}
      {...field}
      error={Boolean(touched && error)}
      helperText={touched && error}
    />
  );
});
