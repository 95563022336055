import * as yup from 'yup';
import { ReactElement, ReactNode } from 'react';
import { Form, Formik } from 'formik';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { CategoryFormValues } from '../../models';
import { CategoryFormBody } from './CategoryFormBody';

interface CategoryFormProps {
  title: ReactNode;
  description: ReactNode;
  onClose: () => void;
  onSubmit: (input: CategoryFormValues) => void;
  initialValues: CategoryFormValues;
  loading?: boolean;
  hasChildren?: boolean;
}

const FIELD_REQUIRED_MSG = 'This field is required';
const EXPECTED_COLOR = 'Expected valid RGB color';
const VALID_UUID = 'Unexpected value was selected';

const validationSchema = yup.object({
  name: yup.string().required(FIELD_REQUIRED_MSG),
  color: yup
    .string()
    .required(FIELD_REQUIRED_MSG)
    .matches(/#[0-9a-fA-F]{6}/, EXPECTED_COLOR),
  parent_id: yup.string().uuid(VALID_UUID),
});

export const CategoryForm = ({
  title,
  description,
  onClose,
  onSubmit,
  initialValues,
  loading,
  hasChildren,
}: CategoryFormProps): ReactElement => {
  const handleSubmit = (formValues: CategoryFormValues) => onSubmit(formValues);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <DialogTitle>
          <Typography fontWeight="medium">{title}</Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {description}
          </Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ bgcolor: 'white' }}>
          <CategoryFormBody hasChildren={hasChildren} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton loading={loading} type="submit">
            Save
          </LoadingButton>
        </DialogActions>
      </Form>
    </Formik>
  );
};
