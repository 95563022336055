import { ReactElement, SVGAttributes } from 'react';
import { Box } from '@mui/material';
import { ColorPickerItemProps } from './models';

const gap = 1;
const circleRadius = 11;
const strokeWidth = 1;
const outerCircleRadius = circleRadius + strokeWidth + gap;
const x = outerCircleRadius + 2;
const transform = `translate(${x}, ${x})`;

const innerCircleParams = (color: string): SVGAttributes<SVGCircleElement> => ({
  r: circleRadius,
  fill: color,
  transform,
});

const outerCircleParams = (color: string): SVGAttributes<SVGCircleElement> => ({
  r: outerCircleRadius,
  stroke: color,
  fillOpacity: 0,
  strokeWidth,
  transform,
});

const containerStyles = {
  height: `${2 * x}px`,
  width: `${2 * x}px`,
  display: 'flex',
};

export const CircleColor = (props: ColorPickerItemProps): ReactElement => {
  const { color, onChange, selected } = props;

  return (
    <Box sx={containerStyles}>
      <svg onClick={() => onChange(color)}>
        <circle {...innerCircleParams(color)} />
        {selected && <circle {...outerCircleParams(color)} />}
      </svg>
    </Box>
  );
};
