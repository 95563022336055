import React, { ReactElement } from 'react';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { Button, Stack } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { AssignCategoryAction } from './AssignCategoryAction';
import { AssignAccountAction } from './AssignAccountAction';
import { ConditionalTooltip } from '../../../components/ConditionalTooltip';

export interface TransactionsGridToolbarProps {
  selected: boolean;
  onAccountChange: (next: string) => void;
  onCategoryChange: (next: string | null) => void;
  onDelete: () => void;
}

export const TransactionsGridToolbar = ({
  selected,
  onAccountChange,
  onCategoryChange,
  onDelete,
}: TransactionsGridToolbarProps): ReactElement => (
  <GridToolbarContainer>
    <Stack spacing={2} direction="row" mx={1} sx={{ overflowX: 'auto' }}>
      <AssignCategoryAction onSubmit={onCategoryChange} disabled={!selected} />
      <AssignAccountAction onSubmit={onAccountChange} disabled={!selected} />
      <ConditionalTooltip
        text="Select rows in the table below to enable this action"
        condition={!selected}
      >
        <Button
          variant="text"
          onClick={onDelete}
          startIcon={<DeleteIcon />}
          disabled={!selected}
          sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}
        >
          Remove
        </Button>
      </ConditionalTooltip>
    </Stack>
  </GridToolbarContainer>
);
