import React, { ReactElement } from 'react';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Box, Button } from '@mui/material';
import { ImportDataShape } from './interfaces';
import { transactionFormValuesToInput } from '../../transactions/models';
import {
  ImportTransactionsVariables,
  useImportTransactions,
} from '../../transactions/hooks/useImportTransactions';
import { GenericError, LoadingSpinner } from '../../components';
import { ImportSteps, stepsConfig } from '../config/importSteps';
import { ImportStepHeader } from './ImportStepHeader';
import { Paths } from '../../core/paths';

interface Props {
  data: ImportDataShape;
  onPrev: () => void;
}

export const ImportDataStep = ({ data: { transactions }, onPrev }: Props): ReactElement => {
  const stepConfig = stepsConfig[ImportSteps.IMPORT];
  const [doImport, { data, error, loading }] = useImportTransactions();

  const uniqueCategories = new Set(transactions.map(({ category }) => category)).size;
  const success = Boolean(data && data.import.count > 0);
  const handlePrev = () => {
    onPrev();
  };

  const handleClickImport = () => {
    const variables: ImportTransactionsVariables = {
      transactions: transactions.map(transactionFormValuesToInput),
    };
    doImport({ variables }).catch(console.error);
  };

  return (
    <>
      <ImportStepHeader config={stepConfig} />
      <GenericError
        error={error}
        title="Failed to import transactions"
        description="We're sorry, importing your transactions didn't work. Please try again."
      >
        <Box py={2}>
          <LoadingSpinner loading={loading}>
            {data && success ? (
              <Alert severity="success">
                Successfully imported {data.import.count} transactions.
              </Alert>
            ) : (
              <Alert severity="info">
                <AlertTitle>Importing transactions</AlertTitle>
                You are about to import {transactions.length} transactions to {uniqueCategories}{' '}
                categories.
              </Alert>
            )}
          </LoadingSpinner>
        </Box>
      </GenericError>
      <Box display="flex" justifyContent={success ? 'flex-end' : 'space-between'}>
        {success ? (
          <Button color="success" variant="outlined" href={Paths.TRANSACTIONS}>
            Go to transactions page
          </Button>
        ) : (
          <>
            <Button onClick={handlePrev} variant="text">
              Previous
            </Button>
            <LoadingButton loading={loading} onClick={handleClickImport} variant="contained">
              Import
            </LoadingButton>
          </>
        )}
      </Box>
    </>
  );
};
