import React, { ElementType, ReactElement, useCallback, useMemo } from 'react';
import { Box } from '@mui/material';
import { CategoryModel, isParentCategoryModel } from '../models';
import { useCategories } from '../hooks';
import { Autocomplete } from '../../components/forms/Autocomplete';
import {
  OverridableComponentProps,
  OverridableTypeMap,
} from '../../core/types/overridable-component';
import { AutocompleteOption } from '../../components/forms/Autocomplete/interfaces';
import { CategoryListItem } from './CategoryListItem';
import { listByParent } from '../utils/list-by-parent';

/**
 * Type of component used when `component={Component}` prop is not provided
 */
type DefaultComponent = typeof Autocomplete;

/**
 * Props that are always required
 */
interface CategoryInputOwnProps {
  topLevelOnly?: boolean;
  options?: AutocompleteOption[];
}

/**
 * Configuration of the concrete component (here - CategoryInput)
 */
interface CategoryInputTypeMap<P = {}, D extends ElementType = DefaultComponent>
  extends OverridableTypeMap {
  defaultComponent: D;
  props: P & CategoryInputOwnProps;
}

/**
 * Declares props type depending on the type (or lack of) `component` prop provided
 */
type CategoryInputProps<
  D extends ElementType = CategoryInputTypeMap['defaultComponent']
> = OverridableComponentProps<CategoryInputTypeMap, D>;

export const CategoryInput = <D extends ElementType = CategoryInputTypeMap['defaultComponent']>(
  props: CategoryInputProps<D>,
): ReactElement | null => {
  const {
    topLevelOnly = false,
    component: AutocompleteComponent = Autocomplete,
    label,
    name,
    ...otherProps
  } = props;
  const { data: { categories = [] } = {}, loading } = useCategories();
  const filteredCategories = useMemo(
    () => (topLevelOnly ? categories.filter(isParentCategoryModel) : categories),
    [categories, topLevelOnly],
  );

  const renderOption = useCallback(
    (optionProps: React.HTMLAttributes<HTMLElement>, option: AutocompleteOption) => {
      const category = categories.find(({ id }) => id === option.id);
      if (!category) {
        return null;
      }
      return (
        <Box {...optionProps}>
          <CategoryListItem
            key={category.id}
            sx={{ pl: isParentCategoryModel(category) ? 0 : 1 }}
            {...category}
          />
        </Box>
      );
    },
    [categories],
  );

  if (loading) {
    return null;
  }

  return (
    <AutocompleteComponent
      options={listByParent(filteredCategories).map(
        (category: CategoryModel): AutocompleteOption => ({
          id: category.id,
          label: category.name,
          color: category.color,
        }),
      )}
      label={label}
      name={name}
      renderOption={renderOption}
      {...otherProps}
    />
  );
};
