import { gql } from '@apollo/client/core';
import { QueryResult, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { useMemo } from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material';
import {
  categoryFragment,
  CategoryOverview,
  getBalance,
  getTotalBalance,
} from '../../categories/models';
import { DATE_FORMAT } from '../../core/constants';

const OVERVIEW_DASHBOARD = gql`
  query OverviewDashboard($startDate: date!, $endDate: date!) {
    category(where: { parent_id: { _is_null: true } }) {
      ...CategoryModel
      transactionsAggregate: transactions_aggregate(
        where: { date: { _gte: $startDate, _lte: $endDate } }
      ) {
        aggregate {
          sum {
            amount
          }
        }
      }
      subcategories {
        ...CategoryModel
        transactionsAggregate: transactions_aggregate(
          where: { date: { _gte: $startDate, _lte: $endDate } }
        ) {
          aggregate {
            sum {
              amount
            }
          }
        }
      }
    }
  }

  ${categoryFragment}
`;

interface OverviewDashboardResult {
  category: CategoryOverview[];
}

interface OverviewDashboardFilters {
  startDate: Date;
  endDate: Date;
}

interface OverviewDashboardVariables {
  startDate: string | null;
  endDate: string | null;
}

export const useOverviewDashboardQuery = ({
  startDate,
  endDate,
}: OverviewDashboardFilters): QueryResult<OverviewDashboardResult, OverviewDashboardVariables> => {
  const variables: OverviewDashboardVariables = useMemo(
    () => ({
      startDate: format(startDate, DATE_FORMAT),
      endDate: format(endDate, DATE_FORMAT),
    }),
    [startDate, endDate],
  );
  return useQuery<OverviewDashboardResult, OverviewDashboardVariables>(OVERVIEW_DASHBOARD, {
    variables,
  });
};

interface Item {
  id: string;
  name: string;
  expenses: string;
  sx?: SxProps<Theme>;
}

// TODO Add return types
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCategoryWidget = (category: CategoryOverview) => {
  const columns = [
    { key: 'name' as const, label: 'Category' },
    { key: 'expenses' as const, label: 'Amount' },
  ];
  const other: Item = {
    id: category.id,
    name: 'Other',
    expenses: getBalance(category).toFixed(2),
  };
  const total: Item = {
    id: `${category.id}_total`,
    name: 'Total',
    expenses: getTotalBalance(category).toFixed(2),
    sx: { fontWeight: 'bold' },
  };
  const items: Item[] = category.subcategories.map(
    (subcategory): Item => ({
      id: subcategory.id,
      name: subcategory.name,
      expenses: getBalance(subcategory).toFixed(2),
    }),
  );

  return { id: category.id, title: category.name, items: [...items, other, total], columns };
};

// TODO Add return types
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCategoryOverview = (result: OverviewDashboardResult) =>
  result.category.map(getCategoryWidget);
