import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';
import { StepConfig } from '../config/importSteps';

interface Props {
  config: StepConfig;
}

export const ImportStepHeader = React.memo(
  ({ config }: Props): ReactElement => (
    <Box sx={{ my: 2 }}>
      <Typography variant="h4" component="h2">
        {config.title}
      </Typography>
      <Typography variant="body2">{config.description}</Typography>
    </Box>
  ),
);
