import { gql } from '@apollo/client/core';
import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { TransactionFragment, transactionFragment, TransactionModelInput } from '../models';

const UPDATE_TRANSACTION = gql`
  mutation UpdateTransaction(
    $id: uuid!
    $account_id: uuid
    $amount: float8
    $category_id: uuid
    $date: date
    $description: String
  ) {
    update_transaction_by_pk(
      pk_columns: { id: $id }
      _set: {
        account_id: $account_id
        amount: $amount
        category_id: $category_id
        date: $date
        description: $description
      }
    ) {
      ...TransactionModel
    }
  }

  ${transactionFragment}
`;

interface UpdateTransactionResult {
  update_transaction_by_pk: TransactionFragment;
}

interface UpdateTransactionVariables extends TransactionModelInput {
  id: string;
}

type UseUpdateTransaction = (
  options: MutationHookOptions<UpdateTransactionResult, UpdateTransactionVariables> | undefined,
) => MutationTuple<UpdateTransactionResult, UpdateTransactionVariables>;

export const useUpdateTransaction: UseUpdateTransaction = (options) =>
  useMutation(UPDATE_TRANSACTION, options);
