import { useField } from 'formik';
import React, { ReactElement } from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { ColorInput } from './ColorInput';

interface FormikColorInputProps {
  name: string;
}

export const FormikColorInput = ({ name }: FormikColorInputProps): ReactElement => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<string>(name);
  const hasError = Boolean(touched && error);

  const handleChange = (next: string) => {
    setValue(next, true);
    setTouched(true);
  };

  const handleBlur = () => {
    setTouched(true);
  };

  return (
    <FormControl>
      <ColorInput color={value} onBlur={handleBlur} setColor={handleChange} />
      {hasError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
