import { GridColumns } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { transactionTypeOptions } from '../../transactions/models';
import {
  CategoryEditCell,
  DefaultCell,
  getAmountCell,
  getCategoryCell,
  TransactionTypeCell,
} from '../components/Cell';
import { DATE_FORMAT } from '../../core/constants';
import { TransactionTypeEditCell } from '../components/Cell/TransactionTypeEditCell';
import { AccountEditCell } from '../components/Cell/AccountEditCell';

interface Option {
  value: string;
  label: string;
}

interface ConfigParams {
  accounts: Array<Option & { currency: string }>;
  categories: Array<Option & { color: string }>;
}

export const getTransactionsGridColumns = ({ accounts, categories }: ConfigParams): GridColumns => [
  {
    field: 'type',
    headerName: 'Type',
    editable: true,
    type: 'singleSelect',
    valueOptions: transactionTypeOptions.map(({ id: value, label }) => ({ value, label })),
    valueFormatter: ({ value: cellValue }) => {
      const option = transactionTypeOptions.find(({ id }) => id === cellValue);
      return option ? option.label : cellValue;
    },
    renderCell: TransactionTypeCell,
    renderEditCell: TransactionTypeEditCell,
  },
  {
    field: 'description',
    width: 250,
    headerName: 'Description',
    type: 'string',
    editable: true,
    renderCell: DefaultCell,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    type: 'number',
    width: 100,
    editable: true,
    valueFormatter: ({ value }) => (typeof value === 'number' ? value.toFixed(2) : value),
    renderCell: getAmountCell(accounts),
  },
  {
    field: 'date',
    headerName: 'Date',
    type: 'date',
    width: 180,
    editable: true,
    valueFormatter: ({ value }) => (value instanceof Date ? format(value, DATE_FORMAT) : value),
    renderCell: DefaultCell,
  },
  {
    field: 'account',
    headerName: 'Account',
    width: 200,
    editable: true,
    type: 'string',
    valueGetter: ({ value }) => value,
    valueFormatter: ({ value: cellValue }) => {
      const option = accounts.find(({ value }) => value === cellValue);
      return option ? `${option.label} (${option.currency})` : cellValue;
    },
    renderCell: DefaultCell,
    renderEditCell: AccountEditCell,
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 200,
    editable: true,
    type: 'string',
    valueFormatter: ({ value: cellValue }) => {
      const option = categories.find(({ value }) => value === cellValue);
      return option ? option.label : cellValue;
    },
    valueGetter: ({ value }) => value,
    renderCell: getCategoryCell(categories),
    renderEditCell: CategoryEditCell,
  },
];
