import React, { ReactElement, SyntheticEvent } from 'react';
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  FormControl,
  FormControlProps,
  FormHelperText,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { AutocompleteOption } from './interfaces';

export interface AutocompleteControlProps
  extends Omit<
    MuiAutocompleteProps<AutocompleteOption, false, false, false>,
    'onChange' | 'defaultValue' | 'renderInput'
  > {
  label: string;
  name: string;
  options: AutocompleteOption[];
  required?: boolean;
  disabled?: boolean;
  formControlProps?: FormControlProps;
  inputProps?: TextFieldProps;
  value: AutocompleteOption | null;
  onChange: (next: AutocompleteOption | null, event: SyntheticEvent) => void;
  onBlur?: () => void;
  hasError?: boolean;
  error?: string;
}

/**
 * Controlled autocomplete input building on top of MUI. Provides a set of reasonable defaults
 * and wraps the component with optional error text and form control.
 */
export const AutocompleteControl: React.FC<AutocompleteControlProps> = React.memo(
  ({
    label,
    name,
    required,
    options,
    disabled,
    formControlProps,
    inputProps,
    value: formValue,
    onChange,
    onBlur,
    hasError,
    error,
    ...props
  }): ReactElement => {
    const id = `${name}-autocomplete`;
    const value = options.find((option: AutocompleteOption) => option.id === formValue?.id) || null;
    const handleChange = (event: SyntheticEvent, next: AutocompleteOption | null) =>
      onChange(next, event);

    return (
      <FormControl
        variant="outlined"
        fullWidth
        required={required}
        size="small"
        error={hasError}
        margin="none"
        disabled={disabled}
        {...formControlProps}
      >
        <MuiAutocomplete
          id={id}
          fullWidth
          openOnFocus
          autoSelect
          clearOnBlur
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              label={label}
              error={hasError}
              {...inputProps}
            />
          )}
          options={options}
          value={value as any /* eslint-disable-line @typescript-eslint/no-explicit-any */}
          onChange={handleChange}
          onBlur={onBlur}
          isOptionEqualToValue={(option: AutocompleteOption, lookup: AutocompleteOption) =>
            option?.id === lookup?.id
          }
          {...props}
        />
        {hasError && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  },
);
