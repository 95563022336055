import { gql } from '@apollo/client/core';

export interface AccountModel {
  id: string;
  name: string;
  userId: string;
  defaultFee: number;
  currency: string;
}

// Includes only fields directly in the model - no joins
export const accountFragment = gql`
  fragment AccountModel on account {
    __typename
    id
    name
    userId
    defaultFee
    currency
  }
`;
