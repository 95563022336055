import React, { ComponentProps, ReactElement, ReactNode } from 'react';
import { Avatar, AvatarProps, styled } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import CheckIcon from '@mui/icons-material/Check';

type AnimatedAvatarProps = Omit<AvatarProps, 'component'> & ComponentProps<typeof motion.div>;
const AnimatedAvatar = (props: AnimatedAvatarProps) => <Avatar component={motion.div} {...props} />;

interface SelectableAvatarProps {
  selected: boolean;
  onToggleSelect: () => void;
  color?: string;
  children: ReactNode;
  className?: string;
}

const variants = {
  enter: { width: 0 },
  center: { width: '100%' },
  exit: { width: 0 },
};

const Wrapper = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
`;

export const SelectableAvatar = ({
  selected,
  onToggleSelect,
  color = 'secondary.main',
  className,
  children,
}: SelectableAvatarProps): ReactElement => (
  <Wrapper onClick={onToggleSelect} className={className}>
    <AnimatePresence initial={false}>
      {selected ? (
        <AnimatedAvatar
          key="initial"
          sx={{ bgcolor: 'primary.main', color: 'white', position: 'absolute' }}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
        >
          <CheckIcon />
        </AnimatedAvatar>
      ) : (
        <AnimatedAvatar
          key="selected"
          sx={{ bgcolor: color, position: 'absolute' }}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
        >
          {children}
        </AnimatedAvatar>
      )}
    </AnimatePresence>
  </Wrapper>
);
