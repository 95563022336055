import React, { ComponentType, PropsWithChildren, ReactElement } from 'react';
import CategoryIcon from '@mui/icons-material/FolderOpen';
import { ListItem, ListItemIcon, ListItemProps, ListItemText, SvgIconProps } from '@mui/material';

interface CategoryListItemProps {
  id: string;
  name: string;
  color?: string;
  icon?: ComponentType<SvgIconProps>;
  sx?: ListItemProps['sx'];
  listItemProps?: ListItemProps<'a'>;
}

export const CategoryListItem = React.memo(
  ({
    name,
    color,
    icon: IconComponent = CategoryIcon,
    sx,
    listItemProps,
  }: PropsWithChildren<CategoryListItemProps>): ReactElement => (
    <ListItem dense disableGutters component="a" sx={sx} {...listItemProps}>
      <ListItemIcon>
        <IconComponent htmlColor={color} />
      </ListItemIcon>
      <ListItemText>{name}</ListItemText>
    </ListItem>
  ),
);
