import { gql } from '@apollo/client/core';
import { CategoryModel } from '../../categories/models';

export interface AssetModel {
  id: string;
  name: string;
  category_id: string;
  category: CategoryModel;
  user_id: string;
}

// Includes only fields directly in the model - no joins
export const assetFragment = gql`
  fragment AssetModel on asset {
    __typename
    id
    name
    category_id
    user_id
  }
`;
