import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  ToggleButton,
  ToggleButtonGroup,
  ToggleButtonGroupProps,
  Typography,
} from '@mui/material';
import React, { ReactElement, ReactNode } from 'react';
import { useField } from 'formik';

interface ToggleOption {
  id: string;
  label: ReactNode;
}

interface ToggleButtonInputProps extends Omit<ToggleButtonGroupProps, 'value'> {
  label?: ReactNode;
  id: string;
  name: string;
  options: ToggleOption[];
}

export const ToggleButtonInput = ({
  id,
  label,
  name,
  options,
  onChange: onChangeProp,
  ...props
}: ToggleButtonInputProps): ReactElement => {
  const inputId = `${id}-input`;
  const labelId = `${id}-label`;
  const [{ value, onChange, onBlur }, { touched, error }] = useField(name);
  const hasError = Boolean(touched && error);
  const { size = 'small' } = props;
  const handleChange = (event: React.MouseEvent<HTMLElement>, nextValue: string): void => {
    onChange(event);
    if (onChangeProp) {
      onChangeProp(event, nextValue);
    }
  };

  const control = (
    <ToggleButtonGroup
      id={inputId}
      color="primary"
      value={value}
      aria-labelledby={labelId}
      onChange={handleChange}
      onBlur={onBlur}
      {...props}
      size={size}
    >
      {options.map(({ id: itemId, label: itemLabel }: ToggleOption) => (
        <ToggleButton key={itemId} value={itemId} name={name}>
          {itemLabel}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );

  if (!label) {
    return (
      <FormControl variant="outlined" error={hasError} size="small">
        {control}
      </FormControl>
    );
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth
      error={hasError}
      size={size !== 'large' ? size : undefined}
      sx={{ px: 1.5, my: 0 }}
    >
      <FormControlLabel
        id={labelId}
        label={
          <Typography variant="caption" sx={{ mx: 2 }} color="text.secondary">
            {label}
          </Typography>
        }
        labelPlacement="start"
        control={control}
      />
      {hasError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
