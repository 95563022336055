import { styled } from '@mui/material';

export const PageContent = styled('section')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  '& > *': {
    maxWidth: `${theme.breakpoints.values.md}px`,
    flexShrink: 0,
  },
  [theme.breakpoints.down('md')]: {
    maxHeight: `calc(100vh - ${theme.mixins.toolbar.minHeight}px)`,
    overflowY: 'auto',
  },
}));
