import React, { cloneElement, isValidElement, ReactElement, ReactNode } from 'react';
import { Box, Chip, Menu, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export interface Option {
  value: string;
  element: ReactElement;
  display: ReactNode;
}

export interface PillDropdownProps {
  selected: Option['value'] | null;
  onChange: (nextValue: Option['value']) => void;
  children: ReactNode;
  labelId?: string;
  id?: string;
}

export const PillDropdown = ({
  selected,
  onChange,
  children,
  id,
  labelId,
}: PillDropdownProps): ReactElement | null => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (value: string) => {
    onChange(value);
    handleClose();
  };

  const options =
    React.Children.map(children, (child): Option | null => {
      if (!isValidElement(child)) {
        return null;
      }
      const { onClick, value, children: display } = child.props;
      const element = cloneElement(child, {
        ...child.props,
        key: value,
        onClick: (event: React.MouseEvent<HTMLElement>): void => {
          if (onClick) {
            onClick(event);
          }
          handleChange(value);
        },
      });
      return { element, value, display };
    })?.filter((opt) => !!opt) || [];

  const selectedOption = options.find(({ value }) => value === selected);

  if (!selectedOption) {
    return null;
  }

  return (
    <>
      <Chip
        variant="outlined"
        color="primary"
        id={id}
        aria-labelledby={labelId}
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        label={
          <Box display="flex" alignItems="center">
            <Typography variant="button">{selectedOption.display}</Typography>
            {anchorEl ? (
              <ExpandLessIcon sx={{ verticalAlign: 'middle' }} />
            ) : (
              <ExpandMoreIcon sx={{ verticalAlign: 'middle' }} />
            )}
          </Box>
        }
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          dense: true,
        }}
        elevation={1}
      >
        {options.map(({ element }) => element)}
      </Menu>
    </>
  );
};
