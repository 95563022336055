import { GridRenderEditCellParams } from '@mui/x-data-grid';
import React, { ReactElement, ReactNode, useCallback } from 'react';
import { MenuItem, Select } from '@mui/material';

interface Option {
  value: string;
  label: string;
}

const TransactionTypeEditCellBody = React.memo(
  ({ id, value, api, field, colDef: { valueOptions } }: GridRenderEditCellParams): ReactElement => {
    const onChange = useCallback(
      (event) => {
        api.setEditCellValue({ id, field, value: event.target.value }, event);
        setTimeout(() => {
          api.commitCellChange({ id, field }, event);
          api.setCellMode(id, field, 'view');
        }, 1);
      },
      [api, field, id],
    );

    return (
      <Select name="transaction-type" value={value} onChange={onChange}>
        {valueOptions.map((option: Option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    );
  },
);

export const TransactionTypeEditCell = (props: GridRenderEditCellParams): ReactNode => (
  <TransactionTypeEditCellBody {...props} />
);
