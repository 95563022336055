import { PropsWithChildren, ReactElement, useState } from 'react';
import { AnimateSharedLayout, motion } from 'framer-motion';
import { IconButton, Theme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { SxProps } from '@mui/system';
import { ExpandedMenu } from './ExpandedMenu';

interface Props {
  id: string;
  menuSx?: SxProps<Theme>;
}

export const ExpandableMenu = ({
  id,
  children,
  menuSx,
}: PropsWithChildren<Props>): ReactElement => {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <div id={`expandable-menu-${id}`}>
      <AnimateSharedLayout>
        {isExpanded ? (
          <>
            <ExpandedMenu id={id} onClose={() => setIsExpanded(false)} sx={menuSx}>
              {children}
            </ExpandedMenu>
          </>
        ) : (
          <IconButton component={motion.button} layoutId={id} onClick={() => setIsExpanded(true)}>
            <MenuIcon />
          </IconButton>
        )}
      </AnimateSharedLayout>
    </div>
  );
};
