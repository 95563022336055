import { ReactElement } from 'react';
import { Button, ButtonProps, SvgIcon, Typography, Box } from '@mui/material';
import FindInPageTwoToneIcon from '@mui/icons-material/FindInPageTwoTone';

interface NoResourcesFoundProps {
  title?: string;
  children?: string;
  icon?: typeof SvgIcon;
  callToAction?: {
    call: string;
    action: () => void;
    icon?: ButtonProps['startIcon'];
  };
}

export const NoResourcesFound = ({
  title = 'No resources found',
  icon: IconComponent = FindInPageTwoToneIcon,
  callToAction,
  children,
}: NoResourcesFoundProps): ReactElement => (
  <Box component="section" sx={{ my: 4, mx: 0, textAlign: 'center' }}>
    <Typography variant="h1" component="span" sx={{ fontSize: '6rem' }}>
      <IconComponent color="secondary" fontSize="inherit" />
    </Typography>
    <Typography variant="subtitle1" component="p">
      {title}
    </Typography>
    {children && (
      <Typography variant="subtitle2" align="center">
        {children}
      </Typography>
    )}
    {callToAction && (
      <Button
        onClick={callToAction?.action}
        color="primary"
        sx={{ my: 2, mx: 0 }}
        startIcon={callToAction?.icon}
      >
        {callToAction?.call}
      </Button>
    )}
  </Box>
);
