import { DashboardType } from '../dashboard/models';

export enum Paths {
  HOME = '/',
  DASHBOARD = '/dashboard/:dashboardId?',
  TRANSACTIONS = '/transactions',
  IMPORT_TRANSACTIONS = '/transactions/import',
}

export interface TransactionsQueryParams {
  startDate?: string;
  categoryId?: string;
}

export interface DashboardParams {
  dashboardId?: DashboardType;
}
