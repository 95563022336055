import { createContext, useContext, useMemo } from 'react';

export interface MultiSelectManager {
  isSelected: (id: string) => boolean;
  toggleSelect: (id: string, next?: boolean) => void;
  selectedItems: Set<string>;
  unselectAll: () => void;
}

export const MultiSelectListContext = createContext<MultiSelectManager | undefined>(undefined);

export const useSelectedItems = (): [Set<string>, () => void] => {
  const multiSelect = useContext(MultiSelectListContext);
  if (!multiSelect) {
    throw new Error(`'useIsSelected' must be used inside 'MultiSelectList' component.`);
  }
  const { selectedItems, unselectAll } = multiSelect;
  return [selectedItems, unselectAll];
};

type SelectState = [boolean, () => void];
export const useSelectState = (id: string): SelectState => {
  const multiSelect = useContext(MultiSelectListContext);
  if (!multiSelect) {
    throw new Error(`'useToggleSelect' must be used inside 'MultiSelectList' component.`);
  }
  return useMemo(() => {
    const { isSelected, toggleSelect } = multiSelect;
    return [isSelected(id), (next?: boolean) => toggleSelect(id, next)];
  }, [multiSelect, id]);
};
