import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { Location } from 'history';

type UseSettingResult = [
  string | null,
  (nextValue: string | undefined) => void,
  <T extends object>(nextValue: string | undefined) => Location<T>,
];

export const useSetting = (key: string): UseSettingResult => {
  const history = useHistory();
  const location = useLocation();
  const params = new window.URLSearchParams(location.search);
  const currentValue = params.get(key);
  const getNextLocation = useCallback(
    <T extends object>(nextValue: string | undefined): Location<T> => {
      const nextParams = new window.URLSearchParams(location.search);
      if (!nextValue) {
        nextParams.delete(key);
      } else {
        nextParams.set(key, nextValue);
      }
      return {
        ...location,
        search: `?${nextParams.toString()}`,
      };
    },
    [location, key],
  );
  const setValue = useCallback(
    (nextValue: string | undefined) => {
      const nextLocation = getNextLocation(nextValue);
      history.push(nextLocation);
    },
    [history, getNextLocation],
  );
  return useMemo(() => [currentValue, setValue, getNextLocation], [
    currentValue,
    setValue,
    getNextLocation,
  ]);
};
