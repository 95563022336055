import { TransactionFormValues, TransactionType } from '../../transactions/models';

export const parseTransactions = (rawData: string): TransactionFormValues[] =>
  rawData
    .split('\n')
    .map((line: string) => line.split(','))
    .map(
      ([date, description, amountStr]: string[]): TransactionFormValues => {
        const amount = Number(amountStr);
        const [day, month, year] = date.split('/');
        return {
          date: `${year}-${month}-${day}`,
          description,
          amount: Math.abs(amount),
          type: amount > 0 ? TransactionType.INCOME : TransactionType.EXPENSE,
          account: '',
          category: '',
        };
      },
    );
