import { useContext, useEffect } from 'react';
import { PageLayoutSettings } from '../interfaces';
import { LayoutContext } from './AppLayout';

export const usePageLayout = (nextPageLayout: PageLayoutSettings): void => {
  const setPageLayout = useContext(LayoutContext);
  if (!setPageLayout) {
    throw new Error('`PageLayoutSettings` may only appear inside `AppLayout`.');
  }

  useEffect(() => {
    setPageLayout(nextPageLayout);
  }, [nextPageLayout, setPageLayout]);
};
