import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { startOfMonth } from 'date-fns';
import { AccountPicker } from '../../accounts/components/AccountPicker';
import { YearMonthPicker } from '../../components';
import { useSetting } from '../../core/hooks';
import { useTransactionFilters } from '../hooks';

export const TransactionFilters = (): ReactElement => {
  const [selectedAccount, setAccount] = useSetting('accountId');
  const [transactionFilters, setFilters] = useTransactionFilters();
  const onDateChange = (nextDate: Date) => setFilters({ startDate: startOfMonth(nextDate) });

  return (
    <Box id="filters" display="flex" flexWrap="nowrap" gap={1} sx={{ overflowX: 'auto' }}>
      <AccountPicker idPrefix="transactions" selected={selectedAccount} onChange={setAccount} />
      <YearMonthPicker value={transactionFilters.startDate} onChange={onDateChange} />
    </Box>
  );
};
