import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { ReactElement } from 'react';
import memoize from 'lodash/memoize';
import { Box } from '@mui/material';
import { CategoryListItem } from '../../../categories/components';
import { mainColors } from '../../../components/forms';

interface Props extends GridRenderCellParams {
  categories: Array<{ value: string; label: string; color: string }>;
}

const CategoryCell = React.memo(
  ({ value, formattedValue, categories }: Props): ReactElement | null => {
    if (typeof formattedValue !== 'string' || typeof value !== 'string' || !value) {
      return (
        <Box
          display="block"
          width="100%"
          height="100%"
          color="error.light"
          fontSize="caption.fontSize"
        >
          (Must be provided)
        </Box>
      );
    }
    const color = categories.find(({ value: lookup }) => lookup === value)?.color;
    return <CategoryListItem id={value} name={formattedValue} color={color || mainColors[4]} />;
  },
);

export const getCategoryCell = memoize(
  (categories: Props['categories']) => (props: GridRenderCellParams) => (
    <CategoryCell categories={categories} {...props} />
  ),
);
