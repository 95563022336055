import React from 'react';
import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  Theme,
} from '@mui/material';
import MuiSelect from '@mui/material/Select';
import { SxProps } from '@mui/system';
import { SelectOption } from './interfaces';

export interface SelectControlProps {
  label: string;
  name: string;
  required?: boolean;
  items: SelectOption[];
  formControlProps?: FormControlProps;
  value: SelectOption | null;
  onChange: (next: SelectOption | null, event: SelectChangeEvent) => void;
  onBlur?: () => void;
  hasError?: boolean;
  error?: string;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  displayEmpty?: boolean;
}

export const SelectControl: React.FC<SelectControlProps> = React.memo(
  ({
    label,
    name,
    items,
    required,
    formControlProps,
    value: formValue,
    onChange,
    onBlur,
    hasError,
    error,
    disabled,
    sx,
    displayEmpty,
  }) => {
    const itemById = (lookup: SelectOption['id'] | null) =>
      items.find(({ id }) => id === lookup) || null;
    const id = `${name}-select`;
    const labelId = `${id}-label`;
    const selectedOption = itemById(formValue && formValue.id) || null;
    const handleChange = (event: SelectChangeEvent) => {
      const option = itemById(event.target.value);
      onChange(option, event);
    };

    return (
      <FormControl
        variant="outlined"
        fullWidth
        required={required}
        size="small"
        error={hasError}
        disabled={disabled}
        {...formControlProps}
      >
        <InputLabel id={labelId}>{label}</InputLabel>
        <MuiSelect
          id={id}
          labelId={labelId}
          label={label}
          value={selectedOption ? selectedOption.id : ''}
          onChange={handleChange}
          onBlur={onBlur}
          disabled={disabled}
          sx={sx}
          displayEmpty={displayEmpty}
        >
          {items.map(({ id: itemId, name: itemName }) => (
            <MenuItem key={itemId} value={itemId}>
              {itemName}
            </MenuItem>
          ))}
        </MuiSelect>
        {hasError && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    );
  },
);
