export enum ImportSteps {
  DATA,
  ADJUST,
  IMPORT,
}

export interface StepConfig {
  label: string;
  title: string;
  description: string;
}

export const stepsConfig: Record<ImportSteps, StepConfig> = {
  [ImportSteps.DATA]: {
    label: 'Provide data',
    title: 'Enter below transactions to be imported',
    description:
      'Data has to be formatted according to the requirements outlined below. You may assign both category and account in the next step.',
  },
  [ImportSteps.ADJUST]: {
    label: 'Adjust information',
    title: 'Adjust imported transactions',
    description:
      'Below is the table containing transactions you have provided in a previous step.\n' +
      'You may edit any field by double clicking on it. You can also assign category and account to any or all transactions.',
  },
  [ImportSteps.IMPORT]: {
    label: 'Import',
    title: 'Finalise import of transactions',
    description:
      'You are about to import transactions provided in previous steps. Please confirm below if everything seems correct.',
  },
};
