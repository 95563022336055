import { GridRenderEditCellParams } from '@mui/x-data-grid';
import React, { ReactElement, ReactNode, SyntheticEvent, useCallback, useMemo } from 'react';
import { CategoryInput } from '../../../categories/components/CategoryInput';
import { AutocompleteControl } from '../../../components/forms/Autocomplete';
import { AutocompleteOption } from '../../../components/forms/Autocomplete/interfaces';

const inputStyles = {
  '& .MuiFormControl-root': { margin: 0, height: '100%' },
  '& fieldset': { border: '0 !important', height: '100%' },
  '& .MuiInputBase-root': { height: '100%', border: 0, borderRadius: 0 },
  '& .MuiFocused *': { border: '0 !important' },
};

const CategoryEditCellBody = React.memo(
  ({ id, value, api, field, formattedValue }: GridRenderEditCellParams): ReactElement => {
    const onChange = useCallback(
      (next: AutocompleteOption | null, sourceEvent: SyntheticEvent) => {
        if (!next) {
          return;
        }
        api.setEditCellValue({ id, field, value: next.id }, sourceEvent);
        // setTimeout required because of this bug: https://github.com/mui-org/material-ui-x/issues/2836
        setTimeout(() => {
          api.commitCellChange({ id, field });
          api.setCellMode(id, field, 'view');
        }, 1);
      },
      [api, field, id],
    );

    const option: AutocompleteOption | null = useMemo(
      () => (value ? { id: value as string, label: formattedValue as string } : null),
      [value, formattedValue],
    );

    return (
      <CategoryInput
        component={AutocompleteControl}
        label=""
        name="category"
        value={option}
        onChange={onChange}
        sx={inputStyles}
      />
    );
  },
);

export const CategoryEditCell = (props: GridRenderEditCellParams): ReactNode => (
  <CategoryEditCellBody {...props} />
);
