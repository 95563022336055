import { PropsWithChildren, ReactElement } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { TransactionFilters } from '../TransactionFilters';
import { useSelectedItems } from '../../../components/MultiSelectList/MultiSelectListContext';
import { TransactionBatchActions } from './TransactionBatchActions';

const variants = {
  enter: { opacity: 0 },
  center: { opacity: 1 },
  exit: { opacity: 0 },
};

const AnimatedWrapper = ({ children }: PropsWithChildren<{}>) => (
  <motion.div
    variants={variants}
    initial="enter"
    animate="center"
    exit="exit"
    style={{ position: 'absolute', width: '100%' }}
  >
    {children}
  </motion.div>
);

export const TransactionListActions = (): ReactElement => {
  const [selectedTransactions, unselectAll] = useSelectedItems();
  return (
    <div style={{ width: '100%', height: 64, position: 'relative' }}>
      <AnimatePresence initial={false} exitBeforeEnter>
        {selectedTransactions.size === 0 ? (
          <AnimatedWrapper key="filters">
            <TransactionFilters />
          </AnimatedWrapper>
        ) : (
          <AnimatedWrapper key="batch-actions">
            <TransactionBatchActions
              selectedIds={Array.from(selectedTransactions)}
              onRemoveSelection={unselectAll}
            />
          </AnimatedWrapper>
        )}
      </AnimatePresence>
    </div>
  );
};
