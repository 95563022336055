import { useAuth0 } from '@auth0/auth0-react';
import { createContext, ReactElement, ReactNode, useState } from 'react';
import { PageLayout } from '../PageLayout';
import { authedNavOptions, publicNavOptions } from '../../../core/nav-options';
import { AccountMenu } from '../../../auth/components';
import { LayoutSetter, PageLayoutSettings } from '../interfaces';
import { LoadingSpinner } from '../../LoadingSpinner';
import { GenericError } from '../../GenericError';

interface Props {
  children: ReactNode;
}

export const LayoutContext = createContext<LayoutSetter | undefined>(undefined);

export const AppLayout = ({ children }: Props): ReactElement => {
  const { isLoading, isAuthenticated, error } = useAuth0();
  const [pageLayout, setPageLayout] = useState<PageLayoutSettings>();

  return (
    <PageLayout
      primaryNav={isAuthenticated ? authedNavOptions : publicNavOptions}
      auth={<AccountMenu />}
      {...pageLayout}
    >
      <LoadingSpinner loading={isLoading}>
        <GenericError error={error}>
          <LayoutContext.Provider value={setPageLayout}>{children}</LayoutContext.Provider>
        </GenericError>
      </LoadingSpinner>
    </PageLayout>
  );
};
