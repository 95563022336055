import { Box, styled } from '@mui/material';
import { AnimateSharedLayout } from 'framer-motion';
import { PropsWithChildren, ReactElement } from 'react';

const StyledBox = styled(Box)(({ theme }) => ({
  ...theme.mixins.toolbar,
  display: 'flex',
  flexGrow: 1,
  alignItems: 'center',
  flexDirection: 'row',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-end',
  },
}));

export const PrimaryNav = ({ children }: PropsWithChildren<{}>): ReactElement => (
  <StyledBox>
    <AnimateSharedLayout>{children}</AnimateSharedLayout>
  </StyledBox>
);
