import { ReactElement } from 'react';
import { TransactionForm } from '../TransactionForm';
import { useAccounts } from '../../../accounts/hooks/useAccounts';
import {
  TransactionFormValues,
  TransactionModel,
  TransactionModelInput,
  TransactionType,
} from '../../models';
import { useUpdateTransaction } from '../../hooks/useUpdateTransaction';
import { useReadTransaction } from '../../hooks/useReadTransaction';

interface TransactionDialogProps {
  updatedId?: TransactionModel['id'];
  onClose: () => void;
}

const getTransactionType = (assetId: string | null, amount: number): TransactionType => {
  if (assetId) {
    return amount >= 0 ? TransactionType.ASSET_SALE : TransactionType.ASSET_PURCHASE;
  }
  return amount >= 0 ? TransactionType.INCOME : TransactionType.EXPENSE;
};

const getInitialValues = ({
  account_id: account,
  amount,
  category_id: category,
  date,
  description,
  asset_id,
}: TransactionModel): TransactionFormValues => ({
  account,
  amount: Math.abs(amount),
  category,
  date,
  description,
  type: getTransactionType(asset_id, amount),
});

export const EditTransactionDialog = ({
  updatedId,
  onClose,
}: TransactionDialogProps): ReactElement | null => {
  const { data: { transaction } = {}, loading: transactionLoading } = useReadTransaction({
    id: updatedId,
  });
  const { data: { accounts = [] } = {}, loading: accountsLoading } = useAccounts();
  const loading = accountsLoading || transactionLoading;

  const [updateTransaction, { loading: transactionSaving }] = useUpdateTransaction({
    onCompleted: () => {
      onClose();
    },
  });

  if (loading || !transaction) {
    return null;
  }

  const initialValues = getInitialValues(transaction);
  const handleSubmit = (input: TransactionModelInput) => {
    if (updatedId) {
      return updateTransaction({ variables: { ...input, id: updatedId } });
    }
    return Promise.reject();
  };

  return (
    <TransactionForm
      title="Update Transaction"
      description="Update previously recorded expense, money income or asset purchase"
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={transactionSaving}
      initialValues={initialValues}
      accounts={accounts}
    />
  );
};
