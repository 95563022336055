import { gql } from '@apollo/client/core';
import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';

const DELETE_TRANSACTIONS = gql`
  mutation DeleteTransactions($selectedIds: [uuid!]) {
    deleteTransactions: delete_transaction(where: { id: { _in: $selectedIds } }) {
      affected_rows
    }
  }
`;
interface DeleteTransactionsResult {
  deleteTransactions: {
    affected_rows: number;
  };
}

interface Variables {
  selectedIds: string[];
}

type UseDeleteTransactions = (
  options: MutationHookOptions<DeleteTransactionsResult, Variables> | undefined,
) => MutationTuple<DeleteTransactionsResult, Variables>;

export const useDeleteTransactions: UseDeleteTransactions = (options) =>
  useMutation(DELETE_TRANSACTIONS, options);
