import { ReactElement } from 'react';
import { CategoryForm } from '../CategoryForm';
import { CategoryFormValues, CategoryModel } from '../../models';
import { useUpdateCategory, useCategories } from '../../hooks';

interface EditCategoryDialogProps {
  categoryId: string;
  onClose: () => void;
}

const getInitialValues = ({
  name,
  color,
  parent_id: parentId,
}: CategoryModel): CategoryFormValues => ({
  name,
  color,
  parentId: parentId || null,
});

export const EditCategoryDialog = ({
  onClose,
  categoryId,
}: EditCategoryDialogProps): ReactElement | null => {
  const { data: { categories = [] } = {} } = useCategories();
  const category = categories.find(({ id }) => id === categoryId);

  const [updateCategory, { loading: isCategorySaving }] = useUpdateCategory({
    onCompleted: () => {
      onClose();
    },
  });

  if (!category) {
    return null;
  }

  const initialValues = getInitialValues(category);

  const handleSubmit = (input: CategoryFormValues) => {
    if (!category) {
      return Promise.reject();
    }
    const variables = {
      ...input,
      id: category.id,
      parentId: input.parentId || null,
    };
    return updateCategory({ variables });
  };

  return (
    <CategoryForm
      title="Update Category"
      description="Update previously created group of similar transactions"
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={isCategorySaving}
      initialValues={initialValues}
      hasChildren={!!category.subcategories?.length}
    />
  );
};
