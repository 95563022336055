import React, { ReactElement, useRef } from 'react';
import { Button, ButtonProps, Grid, styled } from '@mui/material';
import { useUtils } from '@mui/lab/internal/pickers/hooks/useUtils';
import classNames from 'classnames';
import { startOfMonth } from 'date-fns';

interface Props {
  date: Date | null;
  onChange: (nextDate: Date) => void;
}

const MonthOptionButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.text.primary,
  lineHeight: theme.typography.subtitle1.lineHeight,
  fontSize: theme.typography.body1.fontSize,
  '&.selected': {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.subtitle1.fontSize,
  },
}));

interface MonthOptionProps {
  date: Date;
  onChange: (value: number) => void;
  selectedMonth: number;
}

const MonthOption = ({ date, onChange, selectedMonth }: MonthOptionProps) => {
  const utils = useUtils<Date>();
  const value = utils.getMonth(date);
  const label = utils.format(date, 'monthShort');
  const selected = selectedMonth === value;
  return (
    <Grid item xs={4}>
      <MonthOptionButton
        onClick={() => onChange(value)}
        className={classNames({ selected })}
        fullWidth
        size="medium"
      >
        {label}
      </MonthOptionButton>
    </Grid>
  );
};

export const MonthPicker = ({ date, onChange }: Props): ReactElement => {
  const { current: now } = useRef(startOfMonth(new Date()));
  const utils = useUtils<Date>();
  const selectedMonth = utils.getMonth(date || now);
  const onMonthSelected = (month: number) => {
    const nextDate = utils.setMonth(date || now, month);
    onChange(nextDate);
  };
  return (
    <Grid container spacing={1.5} sx={{ padding: '1rem' }}>
      {utils.getMonthArray(date || now).map((monthDate: Date) => (
        <MonthOption
          key={utils.getMonth(monthDate)}
          date={monthDate}
          onChange={onMonthSelected}
          selectedMonth={selectedMonth}
        />
      ))}
    </Grid>
  );
};
