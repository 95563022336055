import { ReactElement } from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

interface ConditionalTooltipProps {
  condition: boolean | undefined;
  children: ReactElement;
  text: TooltipProps['title'];
}
export const ConditionalTooltip = ({
  text,
  condition = false,
  children,
}: ConditionalTooltipProps): ReactElement =>
  condition ? (
    <Tooltip title={text}>
      <span>{children}</span>
    </Tooltip>
  ) : (
    <>{children}</>
  );
