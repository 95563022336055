import { useState } from 'react';
import { ImportSteps } from '../config/importSteps';

interface ImportManager<DataShape extends object> {
  currentStep: ImportSteps;
  goToNextStep: (updatedData?: Partial<DataShape>) => void;
  goToPreviousStep: (updatedData?: Partial<DataShape>) => void;
  handleSubmit: () => void;
  data: Readonly<DataShape>;
}

export const useImportManager = <DataShape extends object>(
  initial: DataShape,
): ImportManager<DataShape> => {
  const [data, setData] = useState<DataShape>(initial);
  const [currentStep, setCurrentStep] = useState(ImportSteps.DATA);

  const handleSubmit = () => {};

  const goToNextStep = (updatedData?: Partial<DataShape>) => {
    if (currentStep < Object.keys(ImportSteps).length) {
      setCurrentStep((step) => step + 1);
      setData((currentData) => ({ ...currentData, ...updatedData }));
    }
  };

  const goToPreviousStep = (updatedData?: Partial<DataShape>) => {
    if (currentStep > 0) {
      setCurrentStep((step) => step - 1);
      setData((currentData) => ({ ...currentData, ...updatedData }));
    }
  };

  return {
    data,
    currentStep,
    handleSubmit,
    goToNextStep,
    goToPreviousStep,
  };
};
