import { gql } from '@apollo/client/core';
import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { CategoryModel } from '../models';

const DELETE_CATEGORY = gql`
  mutation DeleteCategory($selectedId: uuid!) {
    deletedCategory: delete_category_by_pk(id: $selectedId) {
      id
    }
  }
`;
interface DeleteTransactionsResult {
  deletedCategory: {
    id: CategoryModel['id'];
  };
}

interface Variables {
  selectedId: CategoryModel['id'];
}

type UseDeleteCategory = (
  options: MutationHookOptions<DeleteTransactionsResult, Variables> | undefined,
) => MutationTuple<DeleteTransactionsResult, Variables>;

export const useDeleteCategory: UseDeleteCategory = (options) =>
  useMutation(DELETE_CATEGORY, options);
