import { ReactElement, useMemo } from 'react';
import CreateIcon from '@mui/icons-material/Create';
import { GenericError, LoadingSpinner, MultiSelectList } from '../../components';
import { useCategories } from '../../categories/hooks';
import { useTransactionFilters, useTransactions } from '../hooks';
import { TransactionList, TransactionListActions } from './TransactionList';
import { useSetting } from '../../core/hooks';
import { PageLayoutSettings } from '../../components/layout/interfaces';
import { usePageLayout } from '../../components/layout/AppLayout/usePageLayout';
import { TransactionsSidebar } from './TransactionsSidebar';
import { Dialog } from '../../core/components/dialog-config';
import { useChangeDialog } from '../../core/hooks/useChangeDialog';

export const TransactionsPage = (): ReactElement => {
  const [transactionFilters] = useTransactionFilters();
  const changeDialog = useChangeDialog();

  const [selectedAccount] = useSetting('accountId');
  const {
    data: { categories = [] } = {},
    loading: categoriesLoading,
    error: categoriesError,
  } = useCategories();
  const {
    data: { transactions } = {},
    loading: transactionsLoading,
    error: transactionsError,
  } = useTransactions({
    accountId: selectedAccount,
    ...transactionFilters,
  });

  const selectedCategory = categories.find(({ isSelected }) => isSelected);
  const sidebar = useMemo(
    () => (
      <LoadingSpinner loading={categoriesLoading}>
        <GenericError
          error={categoriesError}
          description="Unexpected error has occurred when loading list of categories. Please try again."
        >
          <TransactionsSidebar
            categories={categories}
            onCreateCategory={() => changeDialog({ dialog: Dialog.CREATE_CATEGORY })}
          />
        </GenericError>
      </LoadingSpinner>
    ),
    [categoriesLoading, categoriesError, categories, changeDialog],
  );

  const pageLayout: PageLayoutSettings = useMemo(
    () => ({
      pageTitle: selectedCategory?.name || 'All transactions',
      secondaryNav: sidebar,
      fab: {
        icon: <CreateIcon />,
        label: 'Add transaction',
        color: 'primary',
        onClick: () => changeDialog({ dialog: Dialog.CREATE_TRANSACTION }),
      },
    }),
    [changeDialog, selectedCategory?.name, sidebar],
  );

  usePageLayout(pageLayout);

  return (
    <>
      <MultiSelectList>
        <TransactionListActions />
        <LoadingSpinner loading={transactionsLoading}>
          <GenericError
            error={transactionsError}
            description="Unexpected error occurred when loading transactions list. Please try again."
          >
            <TransactionList
              transactions={transactions || []}
              onEdit={(updatedId) =>
                changeDialog({ dialog: Dialog.EDIT_TRANSACTION, props: { updatedId } })
              }
            />
          </GenericError>
        </LoadingSpinner>
      </MultiSelectList>
    </>
  );
};
