import { ReactElement } from 'react';
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import ViewListIcon from '@mui/icons-material/ViewList';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { generatePath, Link, useParams } from 'react-router-dom';
import { DashboardParams, Paths } from '../../core/paths';
import { DashboardType } from '../models';

const dashboardsHeader = (
  <ListSubheader
    component={Typography}
    variant="subtitle2"
    sx={{ paddingLeft: '30px', fontSize: '0.8em', fontWeight: 'bold' }}
    disableGutters
  >
    Dashboards
  </ListSubheader>
);

export const DashboardSidebar = (): ReactElement => {
  const { dashboardId } = useParams<DashboardParams>();
  return (
    <Box width="100%" pt={1}>
      <List component="nav" aria-label="Dashboards" dense subheader={dashboardsHeader}>
        <ListItemButton
          component={Link}
          sx={{ paddingLeft: '30px' }}
          selected={dashboardId === DashboardType.ANALYTICS}
          to={generatePath(Paths.DASHBOARD, { dashboardId: DashboardType.ANALYTICS })}
        >
          <ListItemIcon>
            <AnalyticsIcon color="secondary" />
          </ListItemIcon>
          <ListItemText>Analytics</ListItemText>
        </ListItemButton>
        <ListItemButton
          component={Link}
          sx={{ paddingLeft: '30px' }}
          selected={dashboardId === DashboardType.OVERVIEW}
          to={generatePath(Paths.DASHBOARD, { dashboardId: DashboardType.OVERVIEW })}
        >
          <ListItemIcon>
            <ViewListIcon color="secondary" />
          </ListItemIcon>
          <ListItemText>Helicopter view</ListItemText>
        </ListItemButton>
      </List>
    </Box>
  );
};
