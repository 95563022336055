import React, { ReactElement, useState } from 'react';
import { Box, Button, Popover, Stack, Typography } from '@mui/material';
import AccountIcon from '@mui/icons-material/AccountBalance';
import SaveIcon from '@mui/icons-material/DoneAll';
import { AccountModel } from '../../../accounts/models';
import { useAccounts } from '../../../accounts/hooks/useAccounts';
import { SelectOption } from '../../../components/forms/Select/interfaces';
import { AccountInput } from '../../../accounts/components/AccountInput';
import { SelectControl } from '../../../components/forms';
import { ConditionalTooltip } from '../../../components/ConditionalTooltip';

interface Props {
  onSubmit: (id: AccountModel['id']) => void;
  disabled?: boolean;
}

export const AssignAccountAction = ({ onSubmit, disabled }: Props): ReactElement => {
  const { data: { accounts: items = [] } = {} } = useAccounts({ fetchPolicy: 'cache-only' });
  const [selected, setSelected] = useState<SelectOption | null>(items[0]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (next: SelectOption | null) => {
    setSelected(next);
  };

  const handleSubmit = () => {
    if (selected) {
      onSubmit(selected.id);
      handleClose();
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'assign-account-action' : undefined;

  return (
    <>
      <ConditionalTooltip
        text="Select rows in the table below to enable this action"
        condition={disabled}
      >
        <Button
          aria-describedby={id}
          variant="text"
          onClick={handleClick}
          startIcon={<AccountIcon />}
          disabled={disabled}
          sx={{ whiteSpace: 'nowrap', flexShrink: 0 }}
        >
          Assign account
        </Button>
      </ConditionalTooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box padding={2} width={400}>
          <Typography color="text.secondary" fontWeight="bold" variant="body2">
            Assign account to selected transactions
          </Typography>
          <Stack direction="column" alignItems="flex-end" gap={1}>
            <AccountInput
              label="Account"
              name="account"
              component={SelectControl}
              items={items}
              onChange={handleChange}
              value={selected}
            />
            <Button
              type="submit"
              startIcon={<SaveIcon />}
              variant="text"
              onClick={handleSubmit}
              disabled={disabled}
            >
              Assign
            </Button>
          </Stack>
        </Box>
      </Popover>
    </>
  );
};
