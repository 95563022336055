import {
  CreateTransactionDialog,
  DeleteTransactionsDialog,
  EditTransactionDialog,
} from '../../transactions/components/dialogs';
import {
  CreateCategoryDialog,
  DeleteCategoryDialog,
  EditCategoryDialog,
} from '../../categories/components/dialogs';

export enum Dialog {
  CREATE_TRANSACTION = 'CREATE_TRANSACTION',
  EDIT_TRANSACTION = 'EDIT_TRANSACTION',
  DELETE_TRANSACTION = 'DELETE_TRANSACTION',
  CREATE_CATEGORY = 'CREATE_CATEGORY',
  EDIT_CATEGORY = 'EDIT_CATEGORY',
  DELETE_CATEGORY = 'DELETE_CATEGORY',
}

export const dialogComponents = {
  [Dialog.CREATE_TRANSACTION]: CreateTransactionDialog,
  [Dialog.EDIT_TRANSACTION]: EditTransactionDialog,
  [Dialog.DELETE_TRANSACTION]: DeleteTransactionsDialog,
  [Dialog.CREATE_CATEGORY]: CreateCategoryDialog,
  [Dialog.EDIT_CATEGORY]: EditCategoryDialog,
  [Dialog.DELETE_CATEGORY]: DeleteCategoryDialog,
};

export type DialogComponents = typeof dialogComponents;
