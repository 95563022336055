import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  styled,
  Typography,
  TextField,
} from '@mui/material';
import { ChangeEvent, ReactElement, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import { ImportStepHeader } from './ImportStepHeader';
import { ImportSteps, stepsConfig } from '../config/importSteps';
import { ImportDataShape } from './interfaces';
import { FIELD_REQUIRED_MSG, IMPORT_LINE_REGEX, INCORRECT_DATA_FORMAT } from '../constants';
import { parseTransactions } from './parseTransactions';

interface Props {
  data: ImportDataShape;
  onNext: (updatedData?: Partial<ImportDataShape>) => void;
}

const BulletList = styled('ul')`
  list-style-type: disc;
  & li {
    margin: 0.5rem 1.5rem;
  }
`;

function validate(value: string): string | null {
  if (!value) {
    return FIELD_REQUIRED_MSG;
  }
  if (!value.split('\n').every((line) => line.search(IMPORT_LINE_REGEX) !== -1)) {
    return INCORRECT_DATA_FORMAT;
  }
  return null;
}

export const ProvideDataStep = ({ onNext, data }: Props): ReactElement => {
  const stepConfig = stepsConfig[ImportSteps.DATA];
  const [currentValue, setCurrentValue] = useState(data.rawData);
  const [error, setError] = useState<string | null>(validate(data.rawData));

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
    setError(validate(value));
    setCurrentValue(value);
  };

  const handleNext = () => {
    const transactions = parseTransactions(currentValue);
    onNext({ rawData: currentValue, transactions });
  };

  return (
    <>
      <ImportStepHeader config={stepConfig} />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="import-hint-content"
          id="import-hint"
        >
          <Typography variant="subtitle1">
            <HelpIcon color="disabled" sx={{ verticalAlign: 'text-bottom', mr: 2 }} />
            Data formatting requirements
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body2" component="span">
            Following rules must be applied to the imported data:
            <BulletList>
              <li>Each line must contain one transaction</li>
              <li>
                Transaction properties have to be separated by commas, and in the following order:{' '}
                <code>date,description,amount</code>
              </li>
              <li>
                The date has to follow <code>DD/MM/YYYY</code> format
              </li>
              <li>Positive/negative amounts will be interpreted as income/expense respectively</li>
            </BulletList>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <TextField
        id="import-data-field"
        label="Transactions"
        multiline
        rows={10}
        variant="outlined"
        value={currentValue}
        onChange={handleChange}
        error={Boolean(currentValue && error)}
        helperText={currentValue ? error : undefined}
      />
      <Box display="flex" flexDirection="row-reverse">
        <Button onClick={handleNext} disabled={!!error} variant="contained">
          Next
        </Button>
      </Box>
    </>
  );
};
