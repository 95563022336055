import {
  AppBar,
  Avatar,
  Box,
  Chip,
  Hidden,
  IconButton,
  styled,
  Toolbar,
  Typography,
} from '@mui/material';
import { ReactElement, ReactNode } from 'react';
import { NavLink } from './NavLink';
import logo from '../../../static_assets/logo.png';
import { PrimaryNav } from './PrimaryNav';
import { NavOption } from '../interfaces';
import { graphqlHttpServer } from '../../../constants';

const ResponsiveAppBar = styled(AppBar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'static',
  '& > .MuiToolbar-root': {
    padding: `0 ${theme.spacing(2)}`,
    width: '100%',
    maxWidth: `${theme.breakpoints.values.xl}px`,
  },
  [theme.breakpoints.down('md')]: {
    top: 'auto',
    bottom: 0,
    position: 'fixed',
    padding: 0,
  },
}));

export interface PageHeaderProps {
  menu?: ReactNode;
  auth?: ReactNode;
  navOptions: NavOption[];
}

export const PageHeader = ({ menu, auth, navOptions }: PageHeaderProps): ReactElement => (
  <>
    <ResponsiveAppBar color="inherit" elevation={1}>
      <Toolbar disableGutters>
        <Hidden mdDown>
          <IconButton edge="start" color="primary" aria-label="Page logo">
            <Avatar src={logo} variant="square" />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            color="primary"
            sx={{ flexGrow: 1, fontWeight: 700 }}
          >
            Finansive
            {graphqlHttpServer.includes('staging') && (
              <Chip label="Staging" color="primary" sx={{ margin: '1rem' }} size="small" />
            )}
          </Typography>
        </Hidden>
        {menu && <Hidden mdUp>{menu}</Hidden>}
        <PrimaryNav>
          {navOptions.map((option) => (
            <NavLink key={option.to} {...option} />
          ))}
        </PrimaryNav>
        {auth && <Box>{auth}</Box>}
      </Toolbar>
    </ResponsiveAppBar>
  </>
);
