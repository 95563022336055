import { ReactElement, useState } from 'react';
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useCategories, useDeleteCategory } from '../../hooks';

interface DeleteCategoryDialogProps {
  onClose: () => void;
  selectedId: string;
}

export const DeleteCategoryDialog = ({
  selectedId,
  onClose,
}: DeleteCategoryDialogProps): ReactElement | null => {
  const { data: { categories = [] } = {} } = useCategories();
  const [deleteCategory] = useDeleteCategory({ variables: { selectedId } });
  const [inProgress, setInProgress] = useState(false);
  const handleConfirm = () => {
    setInProgress(true);
    deleteCategory().then(() => {
      setInProgress(false);
      onClose();
    });
  };
  const category = categories.find(({ id }) => id === selectedId);
  if (!category) {
    return null;
  }

  return (
    <>
      <DialogTitle>Delete category {category.name}?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            Do you want to delete the <strong>{category.name}</strong> category and{' '}
            <strong>all transactions</strong> within this category?
          </Typography>
          <Alert color="error" sx={{ mt: 2 }}>
            This action cannot be reverted.
          </Alert>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={inProgress}>
          Cancel
        </Button>
        <LoadingButton loading={inProgress} onClick={handleConfirm} autoFocus>
          Delete
        </LoadingButton>
      </DialogActions>
    </>
  );
};
