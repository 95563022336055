import { Alert, AlertTitle, Box, Button } from '@mui/material';
import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import {
  DataGrid,
  GridCellEditCommitParams,
  GridColumns,
  GridEditRowProps,
  GridEditRowsModel,
  GridRowsProp,
  GridSelectionModel,
} from '@mui/x-data-grid';
import * as yup from 'yup';
import { ValidationError } from 'yup';
import { TransactionFormValues, transactionValidationSchema } from '../../transactions/models';
import { ImportSteps, stepsConfig } from '../config/importSteps';
import { ImportStepHeader } from './ImportStepHeader';
import { ImportDataShape } from './interfaces';
import { getTransactionsGridColumns } from '../config/transactionsGridConfig';
import { useCategories } from '../../categories/hooks';
import { useAccounts } from '../../accounts/hooks/useAccounts';
import { GenericError, LoadingSpinner } from '../../components';
import { TransactionsGridToolbar, TransactionsGridToolbarProps } from './TransactionsGridToolbar';
import { CategoryModel } from '../../categories/models';
import { AccountModel } from '../../accounts/models';

interface Props {
  data: ImportDataShape;
  onNext: (updatedData?: Partial<ImportDataShape>) => void;
  onPrev: (updatedData?: Partial<ImportDataShape>) => void;
}

function validateTransactions(transactions: TransactionFormValues[]) {
  return Promise.all(
    transactions.map((transaction) => transactionValidationSchema.validate(transaction)),
  );
}

const validateEditRowModel = (rowModel: GridEditRowProps) =>
  Object.entries(rowModel).reduce<GridEditRowProps>((nextRowModel, [field, cellProps]) => {
    const isValid =
      field in transactionValidationSchema.fields
        ? yup.reach(transactionValidationSchema, field).isValidSync(cellProps.value)
        : true;
    // eslint-disable-next-line no-param-reassign
    nextRowModel[field] = { ...cellProps, error: !isValid };
    return nextRowModel;
  }, {});

export const AdjustDataStep = ({
  onNext,
  onPrev,
  data: { transactions: initTransactions },
}: Props): ReactElement => {
  const stepConfig = stepsConfig[ImportSteps.ADJUST];
  const [transactions, setTransactions] = useState<TransactionFormValues[]>(initTransactions);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [editRowsModel, setEditRowsModel] = useState<GridEditRowsModel>({});
  const [validationError, setValidationError] = useState<string | null>(null);

  const {
    data: { categories = [] } = {},
    loading: categoriesLoading,
    error: categoriesError,
  } = useCategories();
  const {
    data: { accounts = [] } = {},
    loading: accountsLoading,
    error: accountsError,
  } = useAccounts();
  const loading = categoriesLoading || accountsLoading;

  const columns: GridColumns = useMemo(() => {
    if (loading) {
      return [];
    }
    const accountsOptions = accounts.map(({ id: value, name: label, currency }) => ({
      value,
      label,
      currency,
    }));
    const categoriesOptions = categories.map(({ id: value, name: label, color }) => ({
      value,
      label,
      color,
    }));
    return getTransactionsGridColumns({ accounts: accountsOptions, categories: categoriesOptions });
  }, [accounts, categories, loading]);

  const rows: GridRowsProp = useMemo(
    () => transactions.map((transaction, id) => ({ ...transaction, id })),
    [transactions],
  );

  const handlePrev = () => {
    onPrev({ transactions });
  };

  const handleNext = () => {
    validateTransactions(transactions)
      .then(() => onNext({ transactions }))
      .catch(({ path, message }: ValidationError) => {
        const label = columns.find(({ field }) => field === path)?.headerName || path;
        setValidationError(`Error in the column ${label}: ${message}`);
      });
  };

  const handleSelect = useCallback((newSelectionModel: GridSelectionModel) => {
    setSelectionModel(newSelectionModel);
  }, []);

  const handleEdit = useCallback((editModel: GridEditRowsModel) => {
    const updatedRowModels = Object.entries(editModel).map(([id, rowModel]) => [
      id,
      validateEditRowModel(rowModel),
    ]);
    const updatedEditModel = Object.fromEntries(updatedRowModels);
    setEditRowsModel(updatedEditModel);
  }, []);

  const handleEditCommit = useCallback(
    ({ id, field, value }: GridCellEditCommitParams) => {
      const next = transactions.map((transaction: TransactionFormValues, index: number) =>
        index === id ? { ...transaction, [field]: value } : transaction,
      );
      setTransactions(next);
    },
    [transactions],
  );

  const handleAssignCategory = (category: CategoryModel['id'] | null) => {
    const updatedTransactions = transactions.map((transaction, index) =>
      selectionModel.includes(index) ? { ...transaction, category } : transaction,
    );
    setTransactions(updatedTransactions);
  };
  const handleAssignAccount = (account: AccountModel['id']) => {
    const updatedTransactions = transactions.map((transaction, index) =>
      selectionModel.includes(index) ? { ...transaction, account } : transaction,
    );
    setTransactions(updatedTransactions);
  };
  const handleDelete = () => {
    const updatedTransactions = transactions.filter((_, index) => !selectionModel.includes(index));
    setTransactions(updatedTransactions);
    setSelectionModel([]);
  };

  const toolbarProps: TransactionsGridToolbarProps = {
    selected: selectionModel.length > 0,
    onCategoryChange: handleAssignCategory,
    onAccountChange: handleAssignAccount,
    onDelete: handleDelete,
  };

  return (
    <Box display="flex" minHeight="100%" height="100%" flexDirection="column">
      <ImportStepHeader config={stepConfig} />
      {validationError ? (
        <Alert severity="error">
          <AlertTitle>Some issues were found with imported data</AlertTitle>
          {validationError}
        </Alert>
      ) : null}
      <Box flexGrow={1} flexShrink={1} flexBasis={400} py={2}>
        <LoadingSpinner loading={loading}>
          <GenericError
            error={categoriesError}
            description="An error has occurred while loading your categories. Please try again later."
          >
            <GenericError
              error={accountsError}
              description="An error has occurred while loading your accounts. Please try again later."
            >
              <DataGrid
                columns={columns}
                rows={rows}
                editRowsModel={editRowsModel}
                onEditRowsModelChange={handleEdit}
                onSelectionModelChange={handleSelect}
                onCellEditCommit={handleEditCommit}
                selectionModel={selectionModel}
                components={{ Toolbar: TransactionsGridToolbar }}
                componentsProps={{ toolbar: toolbarProps }}
                disableSelectionOnClick
                checkboxSelection
              />
            </GenericError>
          </GenericError>
        </LoadingSpinner>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Button onClick={handlePrev} variant="text">
          Previous
        </Button>
        <Button onClick={handleNext} variant="contained">
          Next
        </Button>
      </Box>
    </Box>
  );
};
