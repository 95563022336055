import { GridRenderEditCellParams } from '@mui/x-data-grid';
import React, { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import { SelectControl } from '../../../components/forms';
import { AccountInput } from '../../../accounts/components/AccountInput';
import { SelectOption } from '../../../components/forms/Select/interfaces';

const inputStyles = {
  '& .MuiFormControl-root, & .MuiFormControl-marginNormal': { margin: 0, height: '100%' },
  '& fieldset': { border: '0 !important', height: '100%' },
  '& .MuiInputBase-root, & .MuiSelect-select': {
    height: '100%',
    border: 0,
    borderRadius: 0,
    padding: 0,
  },
  '& .MuiFocused *': { border: '0 !important' },
};

const AccountEditCellBody = React.memo(
  ({ id, value, api, field, formattedValue }: GridRenderEditCellParams): ReactElement => {
    const onChange = useCallback(
      (next: SelectOption | null) => {
        if (!next) {
          return;
        }
        api.setEditCellValue({ id, field, value: next.id });
        // setTimeout required because of this bug: https://github.com/mui-org/material-ui-x/issues/2836
        setTimeout(() => {
          api.commitCellChange({ id, field });
          api.setCellMode(id, field, 'view');
        }, 1);
      },
      [api, field, id],
    );

    const option: SelectOption | null = useMemo(
      () =>
        value && formattedValue ? { id: value as string, name: formattedValue as string } : null,
      [value, formattedValue],
    );

    return (
      <AccountInput
        label={undefined}
        required={false}
        component={SelectControl}
        name="account"
        value={option}
        onChange={onChange}
        formControlProps={{ sx: inputStyles, margin: 'none' }}
        displayEmpty
      />
    );
  },
);

export const AccountEditCell = (props: GridRenderEditCellParams): ReactNode => (
  <AccountEditCellBody {...props} />
);
