import { gql } from '@apollo/client/core';
import * as yup from 'yup';
import { CategoryModel } from '../../categories/models';
import { AssetModel } from '../../assets/models';
import { AccountModel } from '../../accounts/models';

export interface TransactionModel {
  __typename: 'transaction';
  id: string;
  description: string | null;
  date: string;
  amount: number;
  fee: number;
  account_id: string;
  account: AccountModel;
  category_id: string;
  category: CategoryModel;
  asset_id: string | null;
  asset: AssetModel | null;
  asset_units: number | null;
}

// Includes only fields directly in the model - no joins
export const transactionFragment = gql`
  fragment TransactionModel on transaction {
    __typename
    id
    description
    date
    amount
    fee
    asset_units
    account_id
    category_id
    asset_id
  }
`;

export type TransactionFragment = Omit<TransactionModel, 'account' | 'category' | 'asset'>;

export enum TransactionType {
  EXPENSE = 'EXPENSE',
  INCOME = 'INCOME',
  ASSET_PURCHASE = 'ASSET_PURCHASE',
  ASSET_SALE = 'ASSET_SALE',
}

export interface TransactionFormValues {
  type: TransactionType;
  account: string;
  amount: number;
  category: string | null;
  date: string;
  description: string | null;
}

export interface TransactionModelInput
  extends Omit<TransactionFormValues, 'type' | 'category' | 'account' | 'asset'> {
  account_id: TransactionFormValues['account'];
  category_id: TransactionFormValues['category'];
}

export const transactionTypeOptions = [
  { id: TransactionType.EXPENSE, label: 'Expense' },
  { id: TransactionType.INCOME, label: 'Income' },
];

export const transactionFormValuesToInput = ({
  type,
  category: category_id,
  account: account_id,
  ...formValues
}: TransactionFormValues): TransactionModelInput => {
  const isOutFlow = [TransactionType.EXPENSE, TransactionType.ASSET_SALE].includes(type);
  return {
    ...formValues,
    category_id,
    account_id,
    amount: isOutFlow ? -formValues.amount : formValues.amount,
  };
};
const FIELD_REQUIRED_MSG = 'This field is required';
const NON_NEGATIVE_MSG = 'Must be positive';

export const transactionValidationSchema = yup.object({
  account: yup.string().required(FIELD_REQUIRED_MSG),
  amount: yup.number().min(0, NON_NEGATIVE_MSG).required(FIELD_REQUIRED_MSG),
  category: yup.string().required(FIELD_REQUIRED_MSG).nullable(),
  date: yup.string().required(FIELD_REQUIRED_MSG),
  description: yup.string().required(FIELD_REQUIRED_MSG),
});
