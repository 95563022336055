import { FieldFunctionOptions, TypePolicy } from '@apollo/client/cache/inmemory/policies';
import { selectedCategoryVar } from '../reactive-variables';

export const category: TypePolicy = {
  keyFields: ['id'], // Defines model primary key
  // Declares (overrides) caching strategies for fields
  fields: {
    // Declares "virtual" `isSelected` field based on a Reactive Variable
    isSelected: {
      read: (_: boolean, { readField }: FieldFunctionOptions): boolean => {
        const id = readField('id');
        return id === selectedCategoryVar();
      },
    },
  },
};
