import { ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router-dom';
import { Avatar, IconButton, Typography } from '@mui/material';
import { Paths } from '../../core/paths';
import { LoadingSpinner } from '../../components';
import logo from '../../static_assets/logo.png';
import { usePageLayout } from '../../components/layout/AppLayout/usePageLayout';
import { PageLayoutSettings } from '../../components/layout/interfaces';

const pageLayout: PageLayoutSettings = {};

export const HomePage = (): ReactElement => {
  const { isLoading, isAuthenticated } = useAuth0();

  usePageLayout(pageLayout);

  if (isAuthenticated) {
    return <Redirect to={Paths.TRANSACTIONS} />;
  }

  return (
    <LoadingSpinner loading={isLoading} fillSpace>
      <IconButton edge="start" color="primary" aria-label="Page logo">
        <Avatar
          src={logo}
          variant="square"
          sx={{ width: 128, height: 128, filter: 'grayscale(75%)' }}
        />
      </IconButton>
      <Typography variant="h1">Finansive</Typography>
      <Typography component="h2" variant="h4">
        Your final budgeting app
      </Typography>
    </LoadingSpinner>
  );
};
