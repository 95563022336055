import { ReactElement } from 'react';
import Masonry from '@mui/lab/Masonry';
import MasonryItem from '@mui/lab/MasonryItem';
import { GenericError, LoadingSpinner } from '../../../components';
import { useDashboardFilters } from '../../hooks';
import { BaseWidgetConfig } from '../../models/BaseWidgetConfig';

export const Analytics = (): ReactElement | null => {
  useDashboardFilters();
  const data = {};
  const loading = false;
  const error = undefined;

  if (!data) {
    return null;
  }

  const widgets: BaseWidgetConfig[] = [];
  return (
    <LoadingSpinner loading={loading}>
      <GenericError error={error} title="Unexpected error">
        <Masonry columns={2} spacing={2}>
          {widgets.map((widget) => (
            <MasonryItem key={widget.id}>
              <p>Widget</p>
            </MasonryItem>
          ))}
        </Masonry>
      </GenericError>
    </LoadingSpinner>
  );
};
