import { gql } from '@apollo/client/core';
import { QueryResult, useQuery } from '@apollo/client';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { accountFragment, AccountModel } from '../models';

const GET_ACCOUNTS = gql`
  query GetAccounts {
    accounts: account {
      ...AccountModel
    }
  }

  ${accountFragment}
`;

interface GetAccountsResult {
  accounts: Array<AccountModel & { __typename: 'account' }>;
}

export const useAccounts = (
  options?: QueryHookOptions<GetAccountsResult, undefined>,
): QueryResult<GetAccountsResult> => useQuery<GetAccountsResult, undefined>(GET_ACCOUNTS, options);
