import memoize from 'lodash/memoize';
import { CategoryModel, isParentCategoryModel } from '../models';

/**
 * Returns list of categories ordered so that children immediately follow parent
 */
export const listByParent = memoize((categories: CategoryModel[]): CategoryModel[] => {
  const topLevel = categories.filter(isParentCategoryModel);
  const output: CategoryModel[] = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const category of topLevel) {
    output.push(category);
    if (category.subcategories) {
      output.push(...category.subcategories);
    }
  }
  return output;
});
