import { ReactElement } from 'react';
import Masonry from '@mui/lab/Masonry';
import MasonryItem from '@mui/lab/MasonryItem';
import { GenericError, LoadingSpinner } from '../../../components';
import { getCategoryOverview, useDashboardFilters, useOverviewDashboardQuery } from '../../hooks';
import { DashboardTableWidget } from '../Widgets/DashboardTableWidget';

export const Overview = (): ReactElement | null => {
  const [filters] = useDashboardFilters();
  const { data, loading, error } = useOverviewDashboardQuery(filters);

  if (!data) {
    return null;
  }

  const widgets = getCategoryOverview(data);

  return (
    <LoadingSpinner loading={loading}>
      <GenericError
        error={error}
        title="Unexpected error"
        description="Failed to load categories overview. Please try again later."
      >
        <Masonry columns={2} spacing={2}>
          {widgets.map((widget) => (
            <MasonryItem key={widget.id}>
              <DashboardTableWidget {...widget} />
            </MasonryItem>
          ))}
        </Masonry>
      </GenericError>
    </LoadingSpinner>
  );
};
