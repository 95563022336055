import { Box, Hidden, Typography, TypographyProps } from '@mui/material';
import { ReactElement } from 'react';
import { ExtendedFaberge } from './Faberge';
import { FabConfig } from '../interfaces';

interface Props extends TypographyProps {
  fab?: FabConfig;
}

export const PageTitle = ({ fab, ...typographyProps }: Props): ReactElement => (
  <Box textAlign="left" width="100%" display="flex" alignItems="center">
    <Typography variant="h3" color="secondary" position="sticky" {...typographyProps} />
    {fab && (
      <Hidden mdDown>
        <ExtendedFaberge {...fab} sx={{ mx: 2, ...fab.sx }} />
      </Hidden>
    )}
  </Box>
);
