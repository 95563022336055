import { ReactElement, useState } from 'react';
import { Box, Paper } from '@mui/material';
import { ColorBox } from './ColorBox';
import { colorsByMain, mainColors } from './colors';
import { ColorPickerItems, Variant } from './ColorPickerItems';
import { SquareColor, ScaleColor } from './items';

const startColor = mainColors[8];

export const ColorPicker = (): ReactElement => {
  const [mainColor, setMainColor] = useState(startColor);
  const [color, setColor] = useState(startColor);

  const onChange = (col: string) => {
    setColor(col);
    if (mainColors.includes(col)) {
      setMainColor(col);
    }
  };
  return (
    <Paper sx={{ width: 'fit-content', p: 1 }} variant="outlined">
      <Box display="flex" alignItems="end">
        <ColorPickerItems
          selectedColor={mainColor}
          onChange={onChange}
          variant={Variant.grid}
          options={mainColors}
          component={SquareColor}
        />
        <ColorBox color={color} />
      </Box>
      <ColorPickerItems
        selectedColor={color}
        onChange={onChange}
        variant={Variant.scale}
        options={colorsByMain[mainColor].slice(2, 10)}
        component={ScaleColor}
      />
    </Paper>
  );
};
