import { useAuth0 } from '@auth0/auth0-react';
import { Skeleton } from '@mui/material';
import { ReactElement } from 'react';
import { Logout } from './Logout';
import { Login } from './Login';

export const AccountMenu = (): ReactElement => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Skeleton width={64} />;
  }
  return isAuthenticated ? <Logout /> : <Login />;
};
