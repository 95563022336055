import React, { ReactElement } from 'react';
import { Box, Typography } from '@mui/material';

export interface AmountProps {
  currency: string;
  value: number;
}

export const Amount = ({ currency, value }: AmountProps): ReactElement => {
  const isIncome = value > 0;
  return (
    <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
      <Typography variant="h5" component="span">
        {currency.toUpperCase()}
      </Typography>
      <Typography
        sx={{ color: isIncome ? 'success.main' : 'text.primary', marginLeft: '.5rem' }}
        variant="h3"
        component="span"
      >
        {Math.sign(value) < 0 && '-'}
        {Math.floor(Math.abs(value))}
      </Typography>
      <Typography
        sx={{ color: isIncome ? 'success.main' : 'text.primary' }}
        variant="h4"
        component="span"
      >
        .{(value % 1).toFixed(2).slice(-2)}
      </Typography>
    </Box>
  );
};
