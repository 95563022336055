import get from 'lodash/get';
import { ChildCategoryModel, ParentCategoryModel } from './category-model';
import { WithTransactionsAggregate } from '../../transactions/models/transactions-aggregate-model';

type ChildCategoryOverview = WithTransactionsAggregate<ChildCategoryModel>;
export interface CategoryOverview extends WithTransactionsAggregate<ParentCategoryModel> {
  subcategories: Array<ChildCategoryOverview>;
}

type Getter<T> = (category: CategoryOverview | ChildCategoryOverview) => T;

export const getSumOfAmounts: Getter<number | null> = (category) =>
  get(category, ['transactionsAggregate', 'aggregate', 'sum', 'amount'], null);

export const getSumOfFees: Getter<number | null> = (category) =>
  get(category, ['transactionsAggregate', 'aggregate', 'sum', 'fee'], null);

export const getBalance: Getter<number> = (category) => {
  const amountSum = getSumOfAmounts(category);
  const feeSum = getSumOfFees(category);
  return Number(amountSum) + Number(feeSum);
};

export const getTotalBalance: Getter<number> = (category) => {
  const other = getBalance(category);
  const subcategoriesTotal =
    category.subcategories?.reduce((total, subcategory) => total + getBalance(subcategory), 0) || 0;
  return other + subcategoriesTotal;
};
