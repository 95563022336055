import { gql } from '@apollo/client/core';
import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { TransactionFragment, transactionFragment } from '../models';

const MOVE_TRANSACTIONS = gql`
  mutation MoveTransactions($category: uuid = "", $ids: [uuid!] = "") {
    moveTransactions: update_transaction(
      where: { id: { _in: $ids } }
      _set: { category_id: $category }
    ) {
      transactions: returning {
        ...TransactionModel
      }
    }
  }

  ${transactionFragment}
`;

interface MoveTransactionsResult {
  moveTransactions: {
    transactions: TransactionFragment[];
  };
}

interface MoveTransactionsVariables {
  ids: string[];
  category: string;
}

type UseMoveTransactions = (
  options?: MutationHookOptions<MoveTransactionsResult, MoveTransactionsVariables> | undefined,
) => MutationTuple<MoveTransactionsResult, MoveTransactionsVariables>;

export const useMoveTransactions: UseMoveTransactions = (options) =>
  useMutation(MOVE_TRANSACTIONS, options);
