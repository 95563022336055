import { ReactElement } from 'react';
import { format } from 'date-fns';
import { TransactionForm } from '../TransactionForm';
import { useAccounts } from '../../../accounts/hooks/useAccounts';
import { useCreateTransaction } from '../../hooks';
import { TransactionFormValues, TransactionModelInput, TransactionType } from '../../models';
import { AccountModel } from '../../../accounts/models';
import { useSetting } from '../../../core/hooks';

interface TransactionDialogProps {
  onClose: () => void;
}

const getInitialValues = (
  accounts: AccountModel[],
  selectedCategoryId: string | null,
): TransactionFormValues => ({
  account: accounts[0].id,
  amount: 0,
  category: selectedCategoryId,
  date: format(new Date(), 'yyyy-LL-dd'),
  description: '',
  type: TransactionType.EXPENSE,
});

export const CreateTransactionDialog = ({
  onClose,
}: TransactionDialogProps): ReactElement | null => {
  const [selectedCategoryId] = useSetting('categoryId');
  const { data: { accounts = [] } = {}, loading: accountsLoading } = useAccounts();
  const loading = accountsLoading;

  const [createTransaction, { loading: transactionSaving }] = useCreateTransaction({
    onCompleted: () => {
      onClose();
    },
  });

  if (loading) {
    return null;
  }

  const handleSubmit = (variables: TransactionModelInput) => createTransaction({ variables });
  const initialValues = getInitialValues(accounts, selectedCategoryId);

  return (
    <TransactionForm
      title="Create Transaction"
      description="Record here your expense, money income or asset purchase"
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={transactionSaving}
      initialValues={initialValues}
      accounts={accounts}
    />
  );
};
