import { gql } from '@apollo/client/core';
import { QueryResult, useQuery } from '@apollo/client';
import { format } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { transactionFragment, TransactionModel } from '../models';
import { categoryFragment, CategoryModel } from '../../categories/models';
import { assetFragment } from '../../assets/models';
import { accountFragment, AccountModel } from '../../accounts/models';
import { DATE_FORMAT } from '../../core/constants';

const GET_TRANSACTIONS = gql`
  query GetTransactions($categoryId: uuid, $accountId: uuid, $_gte: date = "", $_lt: date = "") {
    transactions: transaction(
      where: {
        category_id: { _eq: $categoryId }
        account_id: { _eq: $accountId }
        date: { _gte: $_gte, _lte: $_lt }
      }
    ) {
      ...TransactionModel
      account {
        ...AccountModel
      }
      category {
        ...CategoryModel
      }
      asset {
        ...AssetModel
      }
    }
  }

  ${transactionFragment}
  ${accountFragment}
  ${categoryFragment}
  ${assetFragment}
`;

const GET_TRANSACTIONS_SUB = gql`
  subscription GetTransactions(
    $categoryId: uuid
    $accountId: uuid
    $_gte: date = ""
    $_lt: date = ""
  ) {
    transactions: transaction(
      where: {
        category_id: { _eq: $categoryId }
        account_id: { _eq: $accountId }
        date: { _gte: $_gte, _lte: $_lt }
      }
    ) {
      ...TransactionModel
      account {
        ...AccountModel
      }
      category {
        ...CategoryModel
      }
      asset {
        ...AssetModel
      }
    }
  }

  ${transactionFragment}
  ${accountFragment}
  ${categoryFragment}
  ${assetFragment}
`;

interface GetTransactionsResult {
  transactions: Array<TransactionModel & { __typename: 'transaction' }>;
}

interface GetTransactionsFilters {
  startDate: Date;
  endDate: Date;
  categoryId: CategoryModel['id'] | undefined;
  accountId: AccountModel['id'] | null;
}

interface GetTransactionsVariables {
  categoryId: string | null;
  accountId: string | null;
  _gte: string | null;
  _lt: string | null;
}

export const useTransactions = ({
  startDate,
  endDate,
  categoryId,
  accountId,
}: GetTransactionsFilters): QueryResult<GetTransactionsResult> => {
  const variables = useMemo(
    () => ({
      categoryId: categoryId || null,
      accountId,
      _gte: format(startDate, DATE_FORMAT),
      _lt: format(endDate, DATE_FORMAT),
    }),
    [categoryId, accountId, startDate, endDate],
  );
  const query = useQuery<GetTransactionsResult, GetTransactionsVariables>(GET_TRANSACTIONS, {
    variables,
  });
  const { subscribeToMore } = query;

  useEffect(() => {
    if (!subscribeToMore) {
      return undefined;
    }

    return subscribeToMore({
      document: GET_TRANSACTIONS_SUB,
      variables,
      onError: function handleTransactionSubError(error) {
        console.error(error);
      },
      updateQuery: (prev, next) => next.subscriptionData.data,
    });
  }, [subscribeToMore, variables]);

  return query;
};
