import { gql } from '@apollo/client/core';

interface BaseCategoryModel {
  __typename: 'category';
  id: string;
  name: string;
  color: string;
  // Client side variable defined in type-policy
  isSelected: boolean;
}

export interface ParentCategoryModel extends BaseCategoryModel {
  subcategories: Array<ChildCategoryModel>;
  parent_id?: undefined;
  parent?: undefined;
}

export interface ChildCategoryModel extends BaseCategoryModel {
  subcategories?: undefined;
  parent_id: string;
  parent: CategoryModel;
}

export type CategoryModel = ParentCategoryModel | ChildCategoryModel;

export const isChildCategoryModel = (category: CategoryModel): category is ChildCategoryModel =>
  !!category.parent_id;
export const isParentCategoryModel = (category: CategoryModel): category is ParentCategoryModel =>
  !category.parent_id;

export const categoryFragment = gql`
  fragment CategoryModel on category {
    __typename
    id
    name
    color
    parent_id
    subcategories {
      __typename
      id
      name
      color
      parent_id
    }
    isSelected @client
  }
`;

export interface CategoryFormValues {
  name: string;
  color: string;
  parentId: string | null;
}

export type CategoryFragment = Omit<CategoryModel, 'parent'>;
