import { ComponentType, ReactElement } from 'react';
import { Box } from '@mui/material';
import { ColorPickerItemProps } from './items';

export enum Variant {
  grid = 'grid',
  scale = 'scale',
}

export interface ColorPickerItemsProps {
  selectedColor: string;
  onChange: (color: string) => void;
  variant: Variant;
  options: string[];
  component: ComponentType<ColorPickerItemProps>;
}

const gridStyles = {
  display: 'grid',
  gridTemplateColumns: 'repeat(14, 1fr)',
  marginBottom: '8px',
  width: 'fit-content',
};

const scaleStyles = {
  display: 'flex',
  alignItems: 'center',
  '&>div:not(:last-child)': {
    marginRight: '1px',
  },
};

export const ColorPickerItems = (props: ColorPickerItemsProps): ReactElement => {
  const { selectedColor, onChange, options, variant, component: Component } = props;
  // const classes = useCirclePickerStyles(props);
  const styles = variant === Variant.scale ? scaleStyles : gridStyles;

  return (
    <Box sx={styles}>
      {options.map((option) => (
        <Component
          key={option}
          color={option}
          onChange={onChange}
          selected={option === selectedColor}
        />
      ))}
    </Box>
  );
};
