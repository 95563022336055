import { ReactElement } from 'react';
import { CategoryModel } from '../models';
import { CategoryTreeItem } from './CategoryTreeItem';

interface SubcategoryListProps {
  subcategories: NonNullable<CategoryModel['subcategories']>;
  onEdit: (id: string) => void;
  onDelete: (id: string) => void;
}

export const SubcategoryList = ({
  subcategories,
  onEdit,
  onDelete,
}: SubcategoryListProps): ReactElement | null => (
  <>
    {subcategories.map(({ id, name, color }) => (
      <CategoryTreeItem
        key={id}
        id={id}
        name={name}
        color={color}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    ))}
  </>
);
