import { gql } from '@apollo/client/core';
import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { TransactionFragment, transactionFragment, TransactionModelInput } from '../models';

const IMPORT_TRANSACTIONS = gql`
  mutation ImportTransactions($transactions: [transaction_insert_input!]!) {
    import: insert_transaction(objects: $transactions) {
      count: affected_rows
      added: returning {
        ...TransactionModel
      }
    }
  }

  ${transactionFragment}
`;

interface ImportTransactionsResult {
  import: {
    count: number;
    added: TransactionFragment[];
  };
}

export interface ImportTransactionsVariables {
  transactions: TransactionModelInput[];
}

type UseImportTransactions = (
  options?: MutationHookOptions<ImportTransactionsResult, ImportTransactionsVariables>,
) => MutationTuple<ImportTransactionsResult, ImportTransactionsVariables>;

export const useImportTransactions: UseImportTransactions = (options) =>
  useMutation(IMPORT_TRANSACTIONS, options);
