import React, { PropsWithChildren, ReactElement } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import classNames from 'classnames';
import styles from './LoadingSpinner.module.css';

interface LoadingSpinnerProps {
  description?: string;
  loading: boolean;
  fillSpace?: boolean;
}

// TODO Support for skeleton component prop
export const LoadingSpinner = ({
  children,
  loading,
  fillSpace,
  description,
}: PropsWithChildren<LoadingSpinnerProps>): ReactElement => {
  if (loading) {
    return (
      <div
        className={classNames(styles.LoadingSpinnerWrapper, {
          [styles.FullScreenWrapper]: fillSpace,
        })}
      >
        <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
          <CircularProgress />
          {description && <Typography>{description}</Typography>}
        </Box>
      </div>
    );
  }
  return <>{children}</>;
};
