import { ReactElement } from 'react';
import { Box } from '@mui/material';
import TickIcon from '@mui/icons-material/Done';
import { ColorPickerItemProps } from './models';

const SQUARE_SIZE = '24px';

export const SquareColor = (props: ColorPickerItemProps): ReactElement => {
  const { color, onChange, selected } = props;

  return (
    <Box
      sx={{ width: SQUARE_SIZE, height: SQUARE_SIZE, border: 'white 1px solid', background: color }}
      onClick={() => onChange(color)}
    >
      {selected && <TickIcon htmlColor="#fff" fontSize="small" />}
    </Box>
  );
};
