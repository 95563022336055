import { gql } from '@apollo/client/core';
import { QueryResult, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { CategoryModel } from '../../categories/models';

const TRANSACTIONS_AGGREGATE = gql`
  query GetTransactionsAggregate($categoryId: uuid) {
    transactionsAggregate: transaction_aggregate(where: { category_id: { _eq: $categoryId } }) {
      aggregate {
        max {
          date
        }
        min {
          date
        }
      }
    }
  }
`;

const TRANSACTIONS_AGGREGATE_SUB = gql`
  subscription GetTransactionsAggregateSub($categoryId: uuid) {
    transactionsAggregate: transaction_aggregate(where: { category_id: { _eq: $categoryId } }) {
      aggregate {
        max {
          date
        }
        min {
          date
        }
      }
    }
  }
`;

export interface TransactionsAggregate {
  transactionsAggregate: {
    aggregate: {
      min: {
        date: string | null;
      };
      max: {
        date: string | null;
      };
    };
  };
}

interface GetTransactionsAggregateVariables {
  categoryId: CategoryModel['id'] | null;
}

export const useTransactionsAggregate = (
  variables: GetTransactionsAggregateVariables,
): QueryResult<TransactionsAggregate> => {
  const query = useQuery<TransactionsAggregate, GetTransactionsAggregateVariables>(
    TRANSACTIONS_AGGREGATE,
    { variables },
  );
  const { subscribeToMore } = query;

  useEffect(() => {
    if (!subscribeToMore) {
      return undefined;
    }
    return subscribeToMore({
      document: TRANSACTIONS_AGGREGATE_SUB,
      onError: console.error,
      variables,
      updateQuery: (prev, next) => next.subscriptionData.data,
    });
  }, [subscribeToMore, variables]);

  return query;
};
