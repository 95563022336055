import { useContext } from 'react';
import { ChangeDialog } from '../components/interfaces';
import { DialogContext } from '../components/DialogContext';

export const useChangeDialog = (): ChangeDialog => {
  const changeDialog = useContext(DialogContext);
  if (!changeDialog) {
    throw new Error('`useChangeDialog` can only be used within `DialogProvider`');
  }
  return changeDialog;
};
