import { ComponentProps, ReactElement } from 'react';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import { Button, ButtonProps, styled } from '@mui/material';
import { motion } from 'framer-motion';
import { NavOption } from '../interfaces';

type NavButtonProps = ButtonProps & ComponentProps<RouterLink> & { selected: boolean };

const NavButton = styled(Button)<NavButtonProps>(({ theme }) => ({
  ...theme.mixins.toolbar,
  borderRadius: 0,
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    fontSize: theme.typography.caption.fontSize,
    minWidth: '40px',
  },
}));

const Underline = styled(motion.div)`
  position: absolute;
  bottom: 0;
  border-bottom-color: ${({ theme }) => theme.palette.primary.main};
  border-bottom-style: solid;
  border-bottom-width: 3px;
  width: 100%;
`;

export const NavLink = ({ to, icon: IconComponent, title }: NavOption): ReactElement => {
  const match = useRouteMatch(to);
  const selected = !!match;
  return (
    <NavButton
      size="large"
      component={RouterLink}
      to={to}
      selected={selected}
      color={match ? 'primary' : 'secondary'}
    >
      <IconComponent />
      <span>{title}</span>
      {selected && <Underline layoutId="nav-link-selected" className="underline" />}
    </NavButton>
  );
};
