import { gql } from '@apollo/client/core';
import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { TransactionFragment, transactionFragment, TransactionModelInput } from '../models';

const CREATE_TRANSACTION = gql`
  mutation CreateTransaction(
    $account_id: uuid
    $amount: float8
    $category_id: uuid
    $date: date
    $description: String
  ) {
    insert_transaction_one(
      object: {
        account_id: $account_id
        amount: $amount
        category_id: $category_id
        date: $date
        description: $description
      }
    ) {
      ...TransactionModel
    }
  }

  ${transactionFragment}
`;

interface CreateTransactionResult {
  insert_transaction_one: TransactionFragment;
}

type UseCreateTransaction = (
  options: MutationHookOptions<CreateTransactionResult, TransactionModelInput> | undefined,
) => MutationTuple<CreateTransactionResult, TransactionModelInput>;

export const useCreateTransaction: UseCreateTransaction = (options) =>
  useMutation(CREATE_TRANSACTION, options);
