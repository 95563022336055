import { gql } from '@apollo/client/core';
import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client';
import { CategoryFormValues, CategoryFragment, categoryFragment } from '../models';

const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($id: uuid!, $color: String, $name: String, $parentId: uuid) {
    update_category_by_pk(
      pk_columns: { id: $id }
      _set: { color: $color, name: $name, parent_id: $parentId }
    ) {
      ...CategoryModel
    }
  }

  ${categoryFragment}
`;

interface UpdateCategoryResult {
  update_category_by_pk: CategoryFragment;
}

interface UpdateCategoryModel extends CategoryFormValues {
  id: string;
}

type UseUpdateCategory = (
  options: MutationHookOptions<UpdateCategoryResult, UpdateCategoryModel> | undefined,
) => MutationTuple<UpdateCategoryResult, UpdateCategoryModel>;

export const useUpdateCategory: UseUpdateCategory = (options) =>
  useMutation(UPDATE_CATEGORY, options);
