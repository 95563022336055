import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { ReactElement, ReactNode } from 'react';

const DefaultCellBody = React.memo(
  ({ formattedValue }: GridRenderCellParams): ReactElement => <span>{formattedValue}</span>,
);

/**
 * Exists because result of React.memo (according to mui-data-grid) is not a function
 */
export const DefaultCell = (props: GridRenderCellParams): ReactNode => (
  <DefaultCellBody {...props} />
);
