type WithKey<Key extends string> = { [key in Key]: string };

export const groupBy = <Key extends string, Item extends WithKey<Key>>(
  key: Key,
  items: Item[],
): Record<string, Item[]> =>
  items.reduce<Record<string, Item[]>>((grouped, item) => {
    const determinant = item[key];
    if (grouped[determinant]) {
      grouped[determinant].push(item);
    } else {
      // eslint-disable-next-line no-param-reassign
      grouped[determinant] = [item];
    }
    return grouped;
  }, {});
