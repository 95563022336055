import { createTheme, ThemeOptions } from '@mui/material';
import { grey } from '@mui/material/colors';

const fontWeight = 500;

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1600,
    },
  },
  palette: {
    background: { default: '#fff' },
    primary: {
      light: '#0466C8',
      main: '#0353A4',
      dark: '#023E7D',
    },
    secondary: {
      light: '#979DAC',
      main: '#7D8597',
      dark: '#33415C',
    },
  },
  typography: {
    h1: {
      fontSize: '2.75rem',
      lineHeight: '3.5rem',
      fontWeight,
    },
    h2: {
      fontSize: '2.25rem',
      lineHeight: '3rem',
      fontWeight,
    },
    h3: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight,
    },
    h4: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      fontWeight,
    },
    h5: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      fontWeight,
    },
    h6: {
      fontSize: '1rem',
      lineHeight: '1.625rem',
      fontWeight,
    },
    subtitle1: {
      fontSize: '1rem',
      lineHeight: '1.625rem',
      fontWeight,
    },
    subtitle2: {
      fontSize: '.875rem',
      lineHeight: '1.5rem',
      fontWeight,
    },
    caption: {
      fontWeight,
    },
    overline: {
      fontWeight,
    },
    button: {
      textTransform: 'none',
    },
    fontFamily: [
      'Manrope',
      'Open Sans',
      '"Helvetica Neue"',
      'Roboto',
      '"Segoe UI"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiInputLabel: {
      defaultProps: {
        // shrink: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        margin: 'normal',
        fullWidth: true,
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: 'normal',
        fullWidth: true,
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          lineHeight: '2.5',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: { backgroundColor: grey[50], borderColor: grey[300] },
      },
    },
  },
} as ThemeOptions);
