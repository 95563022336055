import { gql } from '@apollo/client/core';

export interface TransactionsAggregate {
  aggregate: {
    sum: {
      amount: number;
      fee: number;
    };
  };
}

export const transactionsAggregateFragment = gql`
  fragment TransactionsAggregate on category {
    transactionsAggregate: transactions_aggregate(
      where: { date: { _gte: $startDate, _lte: $endDate }, amount: $amountFilter }
    ) {
      aggregate {
        sum {
          amount
        }
      }
    }
  }
`;

export type WithTransactionsAggregate<T> = T & { transactionsAggregate: TransactionsAggregate };
