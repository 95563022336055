import { useCallback, useMemo } from 'react';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { useSetting } from '../../core/hooks';
import { DATE_FORMAT } from '../../core/constants';

interface Filters {
  startDate: Date;
  endDate: Date;
}

type SetFilters = (next: Partial<Filters>) => void;

export const useDashboardFilters = (): [Filters, SetFilters] => {
  const [startDateSetting, setStartDate] = useSetting('startDate');

  const filters = useMemo(() => {
    const startDate = startDateSetting ? new Date(startDateSetting) : startOfMonth(new Date());
    return {
      startDate,
      endDate: endOfMonth(startDate),
    };
  }, [startDateSetting]);

  const setFilters = useCallback(
    (next: Partial<Filters>): void => {
      if (next.startDate) {
        setStartDate(format(next.startDate, DATE_FORMAT));
      }
    },
    [setStartDate],
  );

  return [filters, setFilters];
};
