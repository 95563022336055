import { Box, Container, Step, StepLabel, Stepper } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import { ViewPicker } from '../../components';
import { ProvideDataStep } from './ProvideDataStep';
import { AdjustDataStep } from './AdjustDataStep';
import { ImportSteps, stepsConfig } from '../config/importSteps';
import { useImportManager } from '../hooks';
import { ImportDataShape } from './interfaces';
import { ImportDataStep } from './ImportDataStep';
import { PageLayoutSettings } from '../../components/layout/interfaces';
import { usePageLayout } from '../../components/layout/AppLayout/usePageLayout';

const initialValues: ImportDataShape = {
  rawData: '',
  transactions: [],
};

export const ImportTransactionsPage = (): ReactElement => {
  const { currentStep, goToNextStep, goToPreviousStep, data } = useImportManager(initialValues);

  const pageLayout: PageLayoutSettings = useMemo(() => ({ pageTitle: 'Import transactions' }), []);

  usePageLayout(pageLayout);

  return (
    <Box width="100%" height="100%" py={4} display="flex" flexDirection="column">
      <Stepper activeStep={currentStep} alternativeLabel>
        {Object.entries(stepsConfig).map(([step, { label }]) => (
          <Step key={step}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Container maxWidth="md" sx={{ my: 4 }}>
        <ViewPicker.Match currentValue={currentStep}>
          <ViewPicker.Case value={ImportSteps.DATA}>
            <ProvideDataStep onNext={goToNextStep} data={data} />
          </ViewPicker.Case>
          <ViewPicker.Case value={ImportSteps.ADJUST}>
            <AdjustDataStep onPrev={goToPreviousStep} onNext={goToNextStep} data={data} />
          </ViewPicker.Case>
          <ViewPicker.Case value={ImportSteps.IMPORT}>
            <ImportDataStep onPrev={goToPreviousStep} data={data} />
          </ViewPicker.Case>
        </ViewPicker.Match>
      </Container>
    </Box>
  );
};
