import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { ReactElement } from 'react';
import { DashboardWidget } from './DashboardWidget';
import { BaseItem, TableWidgetModel } from '../../models';

export const DashboardTableWidget = <ItemType extends BaseItem>({
  title,
  columns,
  items,
}: TableWidgetModel<ItemType>): ReactElement => (
  <DashboardWidget title={title}>
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            {columns.map(({ label, key }) => (
              <TableCell key={key as string}>{label}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item) => (
            <TableRow key={item.id} sx={item?.sx}>
              {columns.map(({ key }) => (
                <TableCell sx={item?.sx}>{item[key]}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </DashboardWidget>
);
