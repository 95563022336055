import { useAuth0 } from '@auth0/auth0-react';
import React, { ReactElement } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { Paths } from '../paths';
import { GenericError, LoadingSpinner } from '../../components';

export const PrivateRoute = (props: RouteProps): ReactElement | null => {
  const { isLoading, isAuthenticated, error } = useAuth0();

  return (
    <LoadingSpinner loading={isLoading} description="Authenticating..." fillSpace>
      <GenericError
        error={error}
        title="Unexpected error"
        description="We're sorry, authentication has failed. Please try again."
      >
        {isAuthenticated ? <Route {...props} /> : <Redirect to={Paths.HOME} />}
      </GenericError>
    </LoadingSpinner>
  );
};
