import { Typography } from '@mui/material';
import React, { ElementType, ReactElement } from 'react';
import { Select } from '../../components/forms';
import { useAccounts } from '../hooks/useAccounts';
import {
  OverridableComponentProps,
  OverridableTypeMap,
} from '../../core/types/overridable-component';
import { SelectOption } from '../../components/forms/Select/interfaces';

/**
 * Type of component used when `component={Component}` prop is not provided
 */
type DefaultComponent = typeof Select;

interface AccountInputOwnProps {
  items?: SelectOption[];
  label?: string;
}

/**
 * Configuration of the concrete component (here - AccountInput).
 */
interface AccountInputTypeMap<P = {}, D extends ElementType = DefaultComponent>
  extends OverridableTypeMap {
  defaultComponent: D;
  props: P & AccountInputOwnProps;
}

/**
 * Declares props type depending on the type (or lack of) `component` prop provided
 */
type AccountInputProps<
  D extends ElementType = AccountInputTypeMap['defaultComponent']
> = OverridableComponentProps<AccountInputTypeMap, D>;

export const AccountInput = <D extends ElementType = AccountInputTypeMap['defaultComponent']>(
  props: AccountInputProps<D>,
): ReactElement | null => {
  const { data: { accounts = [] } = {}, loading } = useAccounts();
  const { name, component: SelectComponent = Select, ...otherProps } = props;
  if (loading) {
    return null;
  }
  return (
    <SelectComponent
      required
      label="Account"
      name={name}
      items={accounts.map(({ id, name: acctName, currency }) => ({
        id,
        name: (
          <>
            {acctName}{' '}
            <Typography display="inline" color="grey.500">
              ({currency})
            </Typography>
          </>
        ),
      }))}
      {...otherProps}
    />
  );
};
