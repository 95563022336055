import React, { PropsWithChildren, ReactElement } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { ApolloError } from '@apollo/client';

interface GenericErrorProps {
  error: Error | ApolloError | undefined;
  title?: string;
  description?: string;
}

const DEFAULT_ERROR_TITLE = 'Error';
const DEFAULT_ERROR_DESCRIPTION = 'Unexpected error has occurred, please try again.';

export const GenericError = ({
  children,
  error,
  title = DEFAULT_ERROR_TITLE,
  description = DEFAULT_ERROR_DESCRIPTION,
}: PropsWithChildren<GenericErrorProps>): ReactElement => {
  if (error) {
    console.error(error);
    return (
      <Alert severity="error">
        <AlertTitle>{title}</AlertTitle>
        {description}
      </Alert>
    );
  }
  return <>{children}</>;
};
