import { PropsWithChildren, ReactElement, useCallback, useMemo, useState } from 'react';
import { MultiSelectListContext, MultiSelectManager } from './MultiSelectListContext';

const useMultiSelect = (): MultiSelectManager => {
  const [selectedItems, setSelectedItems] = useState<Set<string>>(new Set());
  const isSelected = useCallback((id: string) => selectedItems.has(id), [selectedItems]);
  const toggleSelect = useCallback(
    (id: string, next = !selectedItems.has(id)) => {
      const clone = new Set(selectedItems);
      if (next) {
        clone.add(id);
      } else {
        clone.delete(id);
      }
      setSelectedItems(clone);
    },
    [selectedItems],
  );

  const unselectAll = useCallback(() => setSelectedItems(new Set()), []);

  return useMemo(
    () => ({
      isSelected,
      toggleSelect,
      selectedItems,
      unselectAll,
    }),
    [isSelected, toggleSelect, selectedItems, unselectAll],
  );
};

export const MultiSelectList = ({ children }: PropsWithChildren<{}>): ReactElement => {
  const multiSelect = useMultiSelect();

  return (
    <MultiSelectListContext.Provider value={multiSelect}>
      {children}
    </MultiSelectListContext.Provider>
  );
};
