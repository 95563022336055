import { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { List, ListSubheader, Typography } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddIcon from '@mui/icons-material/Add';
import AllTransactionsIcon from '@mui/icons-material/AllInclusive';
import { CategoryTreeItem } from './CategoryTreeItem';
import { CategoryModel, isParentCategoryModel } from '../models';
import { SubcategoryList } from './SubcategoryList';
import { NoResourcesFound } from '../../components';
import { theme } from '../../core/theme';
import { selectedCategoryVar } from '../../core/reactive-variables';
import { useSetting } from '../../core/hooks';
import { Dialog } from '../../core/components/dialog-config';
import { useChangeDialog } from '../../core/hooks/useChangeDialog';

interface CategoryListProps {
  categories: CategoryModel[];
}

const categoryListHeader = (
  <ListSubheader
    component={Typography}
    variant="subtitle2"
    sx={{ paddingLeft: '30px', fontSize: '0.8em', fontWeight: 'bold' }}
    disableGutters
  >
    Categories
  </ListSubheader>
);

export const CategoryList = ({ categories }: CategoryListProps): ReactElement => {
  const filteredCategories = useMemo(() => categories.filter(isParentCategoryModel), [categories]);
  const [categoryId, setCategory] = useSetting('categoryId');

  const changeDialog = useChangeDialog();

  const handleEdit = (id: string) => {
    changeDialog({ dialog: Dialog.EDIT_CATEGORY, props: { categoryId: id } });
  };

  const handleDelete = (id: string) => {
    changeDialog({ dialog: Dialog.DELETE_CATEGORY, props: { selectedId: id } });
  };

  const onCategorySelect = useCallback(
    (_, id: CategoryModel['id']) => {
      setCategory(id === 'all' ? undefined : id);
    },
    [setCategory],
  );

  useEffect(
    function updateFromURL() {
      const selectedCategory = selectedCategoryVar();
      if (categoryId !== selectedCategory) {
        selectedCategoryVar(categoryId ?? undefined);
      }
    },
    [categoryId],
  );

  const selectedCategoryId = useMemo(() => {
    const category = categories.find(({ isSelected }: CategoryModel) => isSelected);
    return category?.id || 'all';
  }, [categories]);

  if (categories.length === 0) {
    return (
      <List component="nav" aria-label="Categories list" subheader={categoryListHeader} dense>
        <NoResourcesFound
          title="No categories were found"
          callToAction={{
            call: 'Create category',
            icon: <AddIcon />,
            action: () => {},
          }}
        />
      </List>
    );
  }

  return (
    <List component="nav" aria-label="Categories list" subheader={categoryListHeader} dense>
      <TreeView
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        selected={selectedCategoryId}
        onNodeSelect={onCategorySelect}
      >
        <CategoryTreeItem
          id="all"
          name="All transactions"
          color={theme.palette.primary.main}
          icon={AllTransactionsIcon}
        />
        {filteredCategories.map(({ id, name, color, subcategories }) => (
          <CategoryTreeItem
            key={id}
            id={id}
            name={name}
            color={color}
            onEdit={handleEdit}
            onDelete={handleDelete}
          >
            {!!subcategories?.length && (
              <SubcategoryList
                subcategories={subcategories}
                onEdit={handleEdit}
                onDelete={handleDelete}
              />
            )}
          </CategoryTreeItem>
        ))}
      </TreeView>
    </List>
  );
};
