import { ReactElement } from 'react';
import { Card, List, ListSubheader } from '@mui/material';
import { TransactionModel } from '../../models';
import { NoResourcesFound } from '../../../components';
import { groupBy } from '../../../utils/groupBy';
import { TransactionItem } from './TransactionItem';

interface TransactionListProps {
  transactions: TransactionModel[];
  onEdit: (id: string) => void;
}

export const TransactionList = ({ transactions, onEdit }: TransactionListProps): ReactElement => {
  if (transactions.length === 0) {
    return <NoResourcesFound title="No transactions in this month" />;
  }
  const byDate = groupBy('date', transactions);
  const sortedDates = Object.keys(byDate).sort().reverse();
  return (
    <>
      {sortedDates.map((date) => (
        <Card variant="outlined" sx={{ width: '100%', padding: '.25rem 0' }} key={date}>
          <List
            subheader={
              <ListSubheader component="h3" sx={{ fontSize: 'h4.fontSize', color: 'text.primary' }}>
                {date}
              </ListSubheader>
            }
            component="section"
          >
            <List>
              {byDate[date].map((transaction: TransactionModel) => (
                <TransactionItem key={transaction.id} {...transaction} onEdit={onEdit} />
              ))}
            </List>
          </List>
        </Card>
      ))}
    </>
  );
};
