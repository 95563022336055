import { ReactElement } from 'react';
import { Box } from '@mui/material';
import { ColorPickerItemProps } from './models';

export const ScaleColor = (props: ColorPickerItemProps): ReactElement => {
  const { color, selected, onChange } = props;

  return (
    <Box
      sx={{
        background: color,
        width: '42px',
        height: selected ? '18px' : '12px',
        borderRadius: selected ? '4px' : 0,
      }}
      onClick={() => onChange(color)}
    />
  );
};
