import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { ReactElement } from 'react';
import memoize from 'lodash/memoize';

interface Props extends GridRenderCellParams {
  accounts: Array<{ value: string; label: string; currency: string }>;
}

const AmountCell = React.memo(
  ({ id, formattedValue, accounts, getValue }: Props): ReactElement => {
    const selectedAccountId = getValue(id, 'account');
    const currency = accounts.find(({ value }) => value === selectedAccountId)?.currency;
    return (
      <span>
        {currency} {formattedValue}
      </span>
    );
  },
);

export const getAmountCell = memoize(
  (accounts: Props['accounts']) => (props: GridRenderCellParams) => (
    <AmountCell accounts={accounts} {...props} />
  ),
);
