import React, { ReactElement, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { TextField } from '../../../components/forms';
import { FormikColorInput } from '../../../components/forms/ColorPicker/FormikColorInput';
import { CategoryInput } from '../CategoryInput';
import { ConditionalTooltip } from '../../../components/ConditionalTooltip';

interface CategoryFormBodyProps {
  hasChildren?: boolean;
}

export const CategoryFormBody = ({ hasChildren }: CategoryFormBodyProps): ReactElement => {
  const [isChildCategory, setIsChildCategory] = useState(false /* TODO Prepopulate */);

  return (
    <>
      <TextField required size="small" name="name" label="Name" margin="none" variant="outlined" />
      <FormikColorInput name="color" />
      <FormGroup>
        <ConditionalTooltip
          condition={hasChildren}
          text="Category that has subcategories cannot be moved under another category."
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isChildCategory}
                onChange={(_, checked) => setIsChildCategory(checked)}
              />
            }
            label="Place it under another category:"
            disabled={hasChildren}
          />
        </ConditionalTooltip>
        {isChildCategory && (
          <CategoryInput
            required
            label="Parent category"
            name="parentId"
            topLevelOnly
            disabled={hasChildren}
          />
        )}
      </FormGroup>
    </>
  );
};
