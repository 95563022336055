import { createContext, PropsWithChildren, ReactElement, useContext, useMemo } from 'react';

type Value = string | number | symbol | null | undefined;
interface MatchProps {
  currentValue: Value;
}

interface CaseProps {
  value: Value;
}

interface ContextValue {
  value: Value;
}

const ViewPickerContext = createContext<ContextValue | undefined>(undefined);

const Match = ({ currentValue, children }: PropsWithChildren<MatchProps>): ReactElement => {
  const manager: ContextValue = useMemo(
    () => ({
      value: currentValue,
    }),
    [currentValue],
  );
  return <ViewPickerContext.Provider value={manager}>{children}</ViewPickerContext.Provider>;
};

const Case = ({ children, value }: PropsWithChildren<CaseProps>): ReactElement | null => {
  const manager = useContext(ViewPickerContext);
  if (!manager) {
    throw new Error('ViewPicker.Case can only be used within ViewPicker.Match');
  }
  if (manager.value !== value) {
    return null;
  }

  return <>{children}</>;
};

export const ViewPicker = { Match, Case };
