import React, { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import { ApolloClient, ApolloProvider, HttpLink, Operation, split } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { graphqlHttpServer, graphqlWSServer } from '../../constants';
import { cache } from '../cache';

export interface ApolloClientProviderProps {
  children: ReactNode;
}

type Headers = Record<string, string | string[]>;
interface WithHeaders {
  headers: Headers;
}

const defaultHeaders: Headers = {
  'X-Hasura-Role': 'user',
};

const isSubscription = ({ query }: Operation): boolean => {
  const definition = getMainDefinition(query);
  return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
};

const useAuthenticatedLink = () => {
  const { getAccessTokenSilently } = useAuth0();

  // Gets redeclared when underlying auth0's client ref changes
  const getParams: () => Promise<WithHeaders> = useCallback(async () => {
    // Obtain (cached) or refresh token asynchronously on every request
    const token = await getAccessTokenSilently();
    return {
      headers: {
        ...defaultHeaders,
        ...(token ? { Authorization: `Bearer ${token}` } : {}),
        Origin: window.location.origin,
      },
    };
  }, [getAccessTokenSilently]);

  const httpLink = useMemo(() => {
    const authLink = setContext(getParams);
    return authLink.concat(new HttpLink({ uri: graphqlHttpServer, credentials: 'include' }));
  }, [getParams]);

  const webSocketLink = useMemo(
    () =>
      new WebSocketLink({
        uri: graphqlWSServer,
        options: { lazy: true, reconnect: true, timeout: 30000, connectionParams: getParams },
      }),
    [getParams],
  );
  return useMemo(() => split(isSubscription, webSocketLink, httpLink), [webSocketLink, httpLink]);
};

export const ApolloClientProvider = ({ children }: ApolloClientProviderProps): ReactElement => {
  const link = useAuthenticatedLink();
  const client = useMemo(() => new ApolloClient({ link, cache }), [link]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
