import DashboardsIcon from '@mui/icons-material/BarChart';
import TransactionsIcon from '@mui/icons-material/ImportExport';
import { generatePath } from 'react-router-dom';
import { Paths } from './paths';
import { DashboardType } from '../dashboard/models';

export const authedNavOptions = [
  {
    to: generatePath(Paths.DASHBOARD, { dashboardId: DashboardType.OVERVIEW }),
    icon: DashboardsIcon,
    title: 'Dashboards',
  },
  { to: Paths.TRANSACTIONS, icon: TransactionsIcon, title: 'Transactions' },
];

export const publicNavOptions = [];
