import { ReactElement, ReactNode } from 'react';
import { Box, Hidden } from '@mui/material';
import { AnimateSharedLayout, motion } from 'framer-motion';
import { PageHeader } from '../PageHeader';
import { PageContent } from './PageContent';
import { PageTitle } from './PageTitle';
import { PageSidebar } from './PageSidebar';
import { Faberge } from './Faberge';
import { ExpandableMenu } from '../ExpandableMenu';
import { NavOption, PageLayoutSettings } from '../interfaces';

interface Props extends PageLayoutSettings {
  primaryNav: NavOption[];
  auth?: ReactNode;
  children: ReactNode;
}

export const PageLayout = ({
  children,
  primaryNav,
  secondaryNav,
  pageTitle,
  fab,
  auth,
}: Props): ReactElement => {
  // Mobile-only
  const menu = secondaryNav && (
    <ExpandableMenu id="secondary-nav" menuSx={{ bottom: '64px' }}>
      {secondaryNav}
    </ExpandableMenu>
  );

  return (
    <AnimateSharedLayout>
      <Box minHeight="100%" height="100%" display="flex" flexDirection="column" alignItems="center">
        <PageHeader menu={menu} auth={auth} navOptions={primaryNav} />
        <Box
          component={motion.div}
          layoutId="secondary-nav"
          flexGrow={1}
          display="flex"
          flexDirection="row"
          maxWidth="xl"
          width="100%"
        >
          {secondaryNav && (
            <Hidden mdDown>
              <PageSidebar>{secondaryNav}</PageSidebar>
            </Hidden>
          )}
          <PageContent>
            <PageTitle fab={fab}>{pageTitle}</PageTitle>
            {children}
          </PageContent>
          {fab && (
            <Hidden mdUp>
              <Faberge aria-label={fab.label} color="primary" {...fab}>
                {fab.icon}
              </Faberge>
            </Hidden>
          )}
        </Box>
      </Box>
    </AnimateSharedLayout>
  );
};
