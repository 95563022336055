import { ReactElement, useMemo } from 'react';
import { generatePath, Route, Switch } from 'react-router-dom';
import { PageLayoutSettings } from '../../components/layout/interfaces';
import { usePageLayout } from '../../components/layout/AppLayout/usePageLayout';
import { DashboardSidebar } from './DashboardSidebar';
import { DashboardFilters } from './DashboardFilters';
import { Analytics, Overview } from './Dashboards';
import { Paths } from '../../core/paths';
import { DashboardType } from '../models';

export const Dashboard = (): ReactElement => {
  const pageLayout: PageLayoutSettings = useMemo(
    () => ({ pageTitle: 'Dashboards', secondaryNav: <DashboardSidebar /> }),
    [],
  );

  usePageLayout(pageLayout);

  return (
    <>
      <DashboardFilters />
      <Switch>
        <Route
          path={generatePath(Paths.DASHBOARD, { dashboardId: DashboardType.OVERVIEW })}
          component={Overview}
        />
        <Route
          path={generatePath(Paths.DASHBOARD, { dashboardId: DashboardType.ANALYTICS })}
          component={Analytics}
        />
      </Switch>
    </>
  );
};
