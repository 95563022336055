import { Form, Formik } from 'formik';
import { Box, Button, Popover, Stack, Typography } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import MoveIcon from '@mui/icons-material/DriveFileMoveOutlined';
import SaveIcon from '@mui/icons-material/DoneAll';
import { LoadingButton } from '@mui/lab';
import { CategoryInput } from '../../../categories/components/CategoryInput';
import { CategoryModel } from '../../../categories/models';

interface FormValues {
  category: CategoryModel['id'] | null;
}

interface MoveTransactionsActionProps {
  onSubmit: (category: FormValues['category']) => Promise<unknown>;
  loading?: boolean;
}

export const MoveTransactionsAction = ({
  onSubmit,
  loading,
}: MoveTransactionsActionProps): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = ({ category }: FormValues) => onSubmit(category).then(handleClose);

  const open = Boolean(anchorEl);
  const id = open ? 'move-transaction-action' : undefined;

  return (
    <>
      <Button
        aria-describedby={id}
        variant="outlined"
        onClick={handleClick}
        startIcon={<MoveIcon />}
      >
        Move
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box padding={2} width={300}>
          <Formik initialValues={{ category: null }} onSubmit={handleSubmit}>
            <Form>
              <Typography color="text.secondary" fontWeight="bold" variant="body2">
                Move selected transactions
              </Typography>
              <Stack direction="column" alignItems="flex-end" gap={1}>
                <CategoryInput label="Category" name="category" />
                <LoadingButton
                  type="submit"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                >
                  Save
                </LoadingButton>
              </Stack>
            </Form>
          </Formik>
        </Box>
      </Popover>
    </>
  );
};
