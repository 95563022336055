import { ReactElement } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ApolloClientProvider, Routes } from './core/components';
import { authApiIdentifier, authClientId, authDomain } from './constants';
import { theme } from './core/theme';
import { AppLayout } from './components/layout/AppLayout';
import { DialogProvider } from './core/components/DialogProvider';

function App(): ReactElement {
  return (
    <Auth0Provider
      domain={authDomain}
      clientId={authClientId}
      redirectUri={window.location.origin}
      audience={authApiIdentifier}
    >
      <ApolloClientProvider>
        <BrowserRouter>
          <CssBaseline />
          <ThemeProvider theme={theme}>
            <DialogProvider>
              <AppLayout>
                <Routes />
              </AppLayout>
            </DialogProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ApolloClientProvider>
    </Auth0Provider>
  );
}

export default App;
