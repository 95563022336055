import { gql } from '@apollo/client/core';
import { LazyQueryResult, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { transactionFragment, TransactionModel } from '../models';
import { categoryFragment } from '../../categories/models';
import { assetFragment } from '../../assets/models';
import { accountFragment } from '../../accounts/models';

const READ_TRANSACTION = gql`
  query ReadTransaction($id: uuid!) {
    transaction: transaction_by_pk(id: $id) {
      ...TransactionModel
      account {
        ...AccountModel
      }
      category {
        ...CategoryModel
      }
      asset {
        ...AssetModel
      }
    }
  }

  ${transactionFragment}
  ${accountFragment}
  ${categoryFragment}
  ${assetFragment}
`;

interface ReadTransactionResult {
  transaction: TransactionModel;
}

interface ReadTransactionVariables {
  id?: string;
}

export const useReadTransaction = (
  variables: ReadTransactionVariables,
): LazyQueryResult<ReadTransactionResult, ReadTransactionVariables> => {
  const [calledId, setCalledId] = useState<ReadTransactionVariables['id'] | undefined>();
  const [readTransaction, queryResult] = useLazyQuery<
    ReadTransactionResult,
    ReadTransactionVariables
  >(READ_TRANSACTION, {
    variables,
  });

  useEffect(() => {
    if (variables.id !== calledId) {
      readTransaction({ variables });
      setCalledId(variables.id);
    }
  }, [variables, readTransaction, calledId]);

  return queryResult;
};
