import { PropsWithChildren, ReactElement, useCallback, useState } from 'react';
import { Dialog, useMediaQuery, useTheme } from '@mui/material';
import { entries } from '../../utils/entries';
import { BaseDialogOption, ChangeDialog } from './interfaces';
import { dialogComponents } from './dialog-config';
import { DialogContext } from './DialogContext';

export const DialogProvider = ({ children }: PropsWithChildren<{}>): ReactElement => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openDialog, setOpenDialog] = useState<BaseDialogOption | null>(null);
  const changeDialog: ChangeDialog = useCallback((next) => {
    setOpenDialog(next);
  }, []);

  const handleClose = useCallback(() => {
    setOpenDialog(null);
  }, []);

  return (
    <DialogContext.Provider value={changeDialog}>
      {entries(dialogComponents).map(([key, DialogComponent]) => (
        <Dialog
          key={key}
          open={key === openDialog?.dialog}
          onClose={handleClose}
          fullScreen={fullScreen}
        >
          <DialogComponent
            onClose={handleClose}
            {
              ...(openDialog?.props as any) /* eslint-disable-line @typescript-eslint/no-explicit-any */
            }
          />
        </Dialog>
      ))}
      {children}
    </DialogContext.Provider>
  );
};
