import { ReactElement, useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useDeleteTransactions } from '../../hooks/useDeleteTransactions';

interface DeleteTransactionsDialogProps {
  onClose: () => void;
  selectedIds: string[];
  onDeleted: () => void;
}

export const DeleteTransactionsDialog = ({
  selectedIds,
  onClose,
  onDeleted,
}: DeleteTransactionsDialogProps): ReactElement => {
  const [deleteTransactions] = useDeleteTransactions({ variables: { selectedIds } });
  const [inProgress, setInProgress] = useState(false);
  const transactionsCount = selectedIds?.length;
  const handleConfirm = () => {
    setInProgress(true);
    deleteTransactions().then(() => {
      setInProgress(false);
      onDeleted();
      onClose();
    });
  };
  return (
    <>
      <DialogTitle>
        {`Delete ${transactionsCount} ${transactionsCount > 1 ? 'transactions' : 'transaction'}`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Do you want to delete {transactionsCount} selected{' '}
          {`${transactionsCount > 1 ? 'transactions' : 'transaction'}`}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={inProgress}>
          Cancel
        </Button>
        <LoadingButton loading={inProgress} onClick={handleConfirm} autoFocus>
          Delete
        </LoadingButton>
      </DialogActions>
    </>
  );
};
