import { GridRenderCellParams } from '@mui/x-data-grid';
import React, { ReactElement, ReactNode } from 'react';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import MoneyIcon from '@mui/icons-material/Money';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import { Box } from '@mui/material';
import { TransactionType } from '../../../transactions/models';

const icons = {
  [TransactionType.EXPENSE]: ReceiptIcon,
  [TransactionType.INCOME]: MoneyIcon,
  [TransactionType.ASSET_PURCHASE]: ShoppingBasketIcon,
  [TransactionType.ASSET_SALE]: PointOfSaleIcon,
};

const isTransactionType = (value: GridRenderCellParams['value']): value is TransactionType =>
  !!value && !!Object.keys(icons).find((type) => type === value);

const TransactionTypeCellBody = React.memo(function TransactionTypeCellBody({
  value,
  formattedValue,
}: GridRenderCellParams): ReactElement | null {
  if (!isTransactionType(value)) {
    return null;
  }
  const Icon = icons[value as TransactionType];
  const isInflow = [TransactionType.INCOME, TransactionType.ASSET_SALE].includes(value);
  return (
    <>
      <Icon color={isInflow ? 'success' : 'action'} />
      <Box component="span" pl={1}>
        {formattedValue}
      </Box>
    </>
  );
});

/**
 * Exists because result of React.memo (according to mui-data-grid) is not a function
 */
export const TransactionTypeCell = (props: GridRenderCellParams): ReactNode => (
  <TransactionTypeCellBody {...props} />
);
