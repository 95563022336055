import React, { ReactElement } from 'react';
import { FormControl, MenuItem } from '@mui/material';
import { useAccounts } from '../hooks/useAccounts';
import { GenericError, LoadingSpinner, PillDropdown } from '../../components';

interface AccountPickerProps {
  idPrefix: string;
  selected: string | null;
  onChange: (nextAccount: string | undefined) => void;
}

const ALL_ACCOUNTS_OPTION = '_ALL';

export const AccountPicker = ({
  idPrefix,
  selected,
  onChange,
}: AccountPickerProps): ReactElement => {
  const {
    data: { accounts = [] } = {},
    loading: accountsLoading,
    error: accountsError,
  } = useAccounts();
  const labelId = `${idPrefix}-acct-picker-label`;
  const fieldId = `${idPrefix}-acct-picker`;
  const handleChange = (value: string) =>
    onChange(value === ALL_ACCOUNTS_OPTION ? undefined : value);

  return (
    <LoadingSpinner loading={accountsLoading}>
      <GenericError error={accountsError}>
        <FormControl sx={{ width: 'auto' }}>
          <PillDropdown
            id={fieldId}
            labelId={labelId}
            selected={selected ?? ALL_ACCOUNTS_OPTION}
            onChange={handleChange}
          >
            <MenuItem value={ALL_ACCOUNTS_OPTION} selected={selected === undefined}>
              All accounts
            </MenuItem>
            {accounts.map(({ id: acctId, name, currency }) => (
              <MenuItem key={acctId} value={acctId} selected={acctId === selected}>
                {name} ({currency})
              </MenuItem>
            ))}
          </PillDropdown>
        </FormControl>
      </GenericError>
    </LoadingSpinner>
  );
};
