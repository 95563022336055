import React from 'react';
import { useField } from 'formik';
import { SelectControl, SelectControlProps } from './SelectControl';
import { SelectOption } from './interfaces';

export type SelectProps = Omit<
  SelectControlProps,
  'value' | 'onChange' | 'onBlur' | 'hasError' | 'error'
>;

export const Select: React.FC<SelectProps> = React.memo((props) => {
  const { name, items } = props;
  const [{ value: formValue }, { touched, error }, { setTouched, setValue }] = useField<
    string | null
  >(name);
  const value = items.find(({ id }) => id === formValue) || null;
  const handleChange = (next: SelectOption | null) => {
    if (next?.id) {
      setValue(next?.id || null);
    }
  };

  const handleBlur = () => {
    setTouched(true);
  };

  const hasError = Boolean(touched && error);

  return (
    <SelectControl
      {...props}
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      hasError={hasError}
      error={error}
    />
  );
});
