import React, { ReactElement, useMemo } from 'react';
import { Box, InputAdornment } from '@mui/material';
import { useField } from 'formik';
import { TextField, ToggleButtonInput } from '../../components/forms';
import { AccountModel } from '../../accounts/models';
import { transactionTypeOptions } from '../models';
import { CategoryInput } from '../../categories/components/CategoryInput';
import { AccountInput } from '../../accounts/components/AccountInput';

interface TransactionFormBodyProps {
  accounts: AccountModel[];
}

export const TransactionFormBody = ({ accounts }: TransactionFormBodyProps): ReactElement => {
  const [{ value: accountId }] = useField('account');
  const selectedAccount = useMemo(() => accounts.find(({ id }) => id === accountId), [
    accountId,
    accounts,
  ]);

  return (
    <>
      <ToggleButtonInput
        id="transaction-type"
        name="type"
        label="Transaction type"
        exclusive
        options={transactionTypeOptions}
      />
      <AccountInput required name="account" label="Account" />
      <Box display="flex" gap={2}>
        <TextField
          required
          size="small"
          name="description"
          label="Description"
          autoComplete="off"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          sx={{ flexBasis: '70%' }}
        />
        <TextField
          required
          size="small"
          name="amount"
          label="Amount"
          type="number"
          variant="outlined"
          inputProps={{ step: '0.01', min: 0 }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{selectedAccount?.currency || ''}</InputAdornment>
            ),
          }}
          sx={{ flexBasis: '30%' }}
        />
      </Box>
      <TextField required name="date" size="small" label="Date" type="date" variant="outlined" />
      <CategoryInput required label="Category" name="category" topLevelOnly={false} />
    </>
  );
};
