import { Box } from '@mui/material';
import { ReactElement } from 'react';
import { startOfMonth } from 'date-fns';
import { useDashboardFilters } from '../hooks';
import { AccountPicker } from '../../accounts/components/AccountPicker';
import { YearMonthPicker } from '../../components';
import { useSetting } from '../../core/hooks';

export const DashboardFilters = (): ReactElement => {
  const [selectedAccount, setAccount] = useSetting('accountId');
  const [{ startDate }, setFilters] = useDashboardFilters();
  const onDateChange = (nextDate: Date) => setFilters({ startDate: startOfMonth(nextDate) });

  return (
    <Box
      id="filters"
      display="flex"
      flexWrap="nowrap"
      width="100%"
      gap={1}
      sx={{ overflowX: 'auto' }}
    >
      <AccountPicker idPrefix="dashboard" selected={selectedAccount} onChange={setAccount} />
      <YearMonthPicker value={startDate} onChange={onDateChange} />
    </Box>
  );
};
