import React, { ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

export const Login = (): ReactElement => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Button color="primary" onClick={loginWithRedirect}>
      Login
    </Button>
  );
};
