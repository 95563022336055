import React, { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Dashboard } from '../../dashboard/components';
import { Paths } from '../paths';
import { HomePage } from '../../homepage/components';
import { PrivateRoute } from './PrivateRoute';
import { TransactionsPage } from '../../transactions/components';
import { ImportTransactionsPage } from '../../imports/components/ImportTransactionsPage';

export const Routes = (): ReactElement => (
  <Switch>
    <PrivateRoute path={Paths.DASHBOARD} component={Dashboard} exact />
    <PrivateRoute path={Paths.TRANSACTIONS} component={TransactionsPage} exact />
    <PrivateRoute path={Paths.IMPORT_TRANSACTIONS} component={ImportTransactionsPage} exact />
    <Route path={Paths.HOME} component={HomePage} />
  </Switch>
);
