import { QueryResult, useQuery } from '@apollo/client';
import { gql } from '@apollo/client/core';
import { useEffect } from 'react';
import { QueryHookOptions } from '@apollo/client/react/types/types';
import { categoryFragment, CategoryModel } from '../models';

const GET_CATEGORIES = gql`
  query GetCategories {
    categories: category(order_by: { name: asc }) {
      ...CategoryModel
    }
  }

  ${categoryFragment}
`;

const GET_CATEGORIES_SUB = gql`
  subscription GetCategoriesSubscription {
    categories: category(order_by: { name: asc }) {
      ...CategoryModel
    }
  }

  ${categoryFragment}
`;

interface GetCategoriesResult {
  categories: CategoryModel[];
}

export const useCategories = (
  options?: QueryHookOptions<GetCategoriesResult>,
): QueryResult<GetCategoriesResult> => {
  const query = useQuery<GetCategoriesResult>(GET_CATEGORIES, options);

  const { subscribeToMore } = query;

  useEffect(() => {
    if (!subscribeToMore) {
      return undefined;
    }

    return subscribeToMore({
      document: GET_CATEGORIES_SUB,
      onError: function handleCategoriesSubError(error) {
        console.error(error);
      },
      updateQuery: (prev, next) => next.subscriptionData.data,
    });
  }, [subscribeToMore]);

  return query;
};
