import React, { ReactElement } from 'react';
import { useField } from 'formik';
import { AutocompleteControl, AutocompleteControlProps } from './AutocompleteControl';
import { AutocompleteOption } from './interfaces';

export type AutocompleteProps = Omit<
  AutocompleteControlProps,
  'value' | 'onChange' | 'onBlur' | 'hasError' | 'error'
>;

/**
 * Uncontrolled variant of Autocomplete, where state is controlled by Formik.
 * Must be used within Formik's forms.
 * @see ./AutocompleteControl
 */
export const Autocomplete: React.FC<AutocompleteProps> = React.memo(
  (props): ReactElement => {
    const { name, options } = props;
    const [{ value: formValue }, { touched, error }, { setValue, setTouched }] = useField<
      string | null
    >(name);
    const value = options.find(({ id: key }) => key === formValue) || null;
    const handleChange = (next: AutocompleteOption | null) => {
      if (next?.id) {
        setValue(next?.id || null);
      }
    };
    const handleBlur = () => {
      setTouched(true);
    };
    const hasError = Boolean(touched && error);

    return (
      <AutocompleteControl
        {...props}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        hasError={hasError}
        error={error}
      />
    );
  },
);
