import { ReactElement, ReactNode } from 'react';
import { Box } from '@mui/material';

interface Props {
  children: ReactNode;
}

export const PageSidebar = ({ children }: Props): ReactElement => (
  <Box width={300} pt={1} borderRight="1px solid rgba(0, 0, 0, 0.12)">
    {children}
  </Box>
);
