import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { MoveTransactionsAction } from './MoveTransactionsAction';
import { useMoveTransactions } from '../../hooks/useMoveTransactions';
import { useChangeDialog } from '../../../core/hooks/useChangeDialog';
import { Dialog } from '../../../core/components/dialog-config';

interface TransactionBatchActionsProps {
  selectedIds: string[];
  onRemoveSelection: () => void;
}

export const TransactionBatchActions = ({
  selectedIds,
  onRemoveSelection,
}: TransactionBatchActionsProps): ReactElement => {
  const [moveTransactions, { loading: moveSaving }] = useMoveTransactions();
  const changeDialog = useChangeDialog();
  const handleMove = (category: string | null) => {
    if (category) {
      moveTransactions({ variables: { category, ids: selectedIds } }).then(() =>
        onRemoveSelection(),
      );
    }
    return Promise.reject();
  };

  const handleDelete = () => {
    changeDialog({
      dialog: Dialog.DELETE_TRANSACTION,
      props: { selectedIds, onDeleted: onRemoveSelection },
    });
  };

  return (
    <>
      <Box
        id="batch-actions"
        display="flex"
        flexWrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
        sx={{ overflowX: 'auto', width: '100%', py: 1.5 }}
      >
        <Typography variant="button">
          <IconButton aria-label="Remove selection" onClick={onRemoveSelection}>
            <CloseIcon />
          </IconButton>
          {selectedIds.length} selected
        </Typography>
        <Stack direction="row" spacing={2}>
          <MoveTransactionsAction onSubmit={handleMove} loading={moveSaving} />
          <Button variant="outlined" startIcon={<DeleteIcon />} onClick={handleDelete}>
            Delete
          </Button>
        </Stack>
      </Box>
    </>
  );
};
