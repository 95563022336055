import React, { ReactElement, useState } from 'react';
import { Button, Collapse, InputLabel, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { colorsByMain, findMainColor, mainColors } from './colors';
import { ColorPickerItems, Variant } from './ColorPickerItems';
import { ScaleColor, SquareColor } from './items';

interface ColorInputProps {
  color: string;
  setColor: (value: string) => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
}

export const ColorInput = (props: ColorInputProps): ReactElement => {
  const { color, setColor, onBlur } = props;
  const [mainColor, setMainColor] = useState(findMainColor(color));
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const toggleColorPicker = () => {
    setColorPickerOpen((open) => !open);
  };

  const onChange = (col: string) => {
    setColor(col);
    if (mainColors.includes(col)) {
      setMainColor(col);
    }
  };

  return (
    <Box sx={{ mt: 2, mb: 1 }} onBlur={onBlur}>
      <InputLabel color="primary" shrink required>
        Selected color
      </InputLabel>
      <Box sx={{ borderRadius: '4px', background: color }}>
        <Button
          onClick={toggleColorPicker}
          sx={{ height: '32px', display: 'flex', justifyContent: 'flex-end' }}
          color="inherit"
          fullWidth
        >
          {colorPickerOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Button>
      </Box>
      <Collapse in={colorPickerOpen} sx={{ mt: 1 }}>
        <ColorPickerItems
          selectedColor={mainColor}
          onChange={onChange}
          variant={Variant.grid}
          options={mainColors}
          component={SquareColor}
        />
        <ColorPickerItems
          selectedColor={color}
          onChange={onChange}
          variant={Variant.scale}
          options={colorsByMain[mainColor].slice(2, 10)}
          component={ScaleColor}
        />
      </Collapse>
    </Box>
  );
};
