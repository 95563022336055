import React, { ReactElement } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

export const Logout = (): ReactElement => {
  const { logout } = useAuth0();

  return (
    <Button color="primary" onClick={() => logout({ returnTo: window.location.origin })}>
      Logout
    </Button>
  );
};
