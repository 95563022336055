import { Dialog, Popover, useMediaQuery, useTheme } from '@mui/material';
import React, { PropsWithChildren, ReactElement } from 'react';

interface PaneProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onClosed: () => void;
}

export const Pane = ({
  children,
  anchorEl,
  onClose,
  onClosed: onExited,
}: PropsWithChildren<PaneProps>): ReactElement => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (isMobile) {
    return (
      <Dialog open={!!anchorEl} onClose={onClose} TransitionProps={{ onExited }} fullScreen>
        {children}
      </Dialog>
    );
  }

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      TransitionProps={{ onExited }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      elevation={1}
      sx={{ maxWidth: '330px' }}
    >
      {children}
    </Popover>
  );
};
