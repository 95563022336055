import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React, { ReactElement } from 'react';
import AddCategoryIcon from '@mui/icons-material/CreateNewFolder';
import { Link } from 'react-router-dom';
import ImportIcon from '@mui/icons-material/UploadFile';
import { CategoryList } from '../../categories/components';
import { CategoryModel } from '../../categories/models';
import { Paths } from '../../core/paths';

export interface TransactionsSidebarProps {
  categories: CategoryModel[];
  onCreateCategory: () => void;
}

export const TransactionsSidebar = ({
  categories,
  onCreateCategory,
}: TransactionsSidebarProps): ReactElement => (
  <Box sx={{ width: '100%' }}>
    <CategoryList categories={categories} />
    <Divider />
    <List component="nav" aria-label="Settings" dense>
      <ListItem button sx={{ paddingLeft: '30px' }} onClick={onCreateCategory}>
        <ListItemIcon>
          <AddCategoryIcon color="secondary" />
        </ListItemIcon>
        <ListItemText>Create category</ListItemText>
      </ListItem>
      <ListItemButton component={Link} to={Paths.IMPORT_TRANSACTIONS} sx={{ paddingLeft: '30px' }}>
        <ListItemIcon>
          <ImportIcon />
        </ListItemIcon>
        <ListItemText>Import transactions</ListItemText>
      </ListItemButton>
    </List>
  </Box>
);
