import { ReactElement, ReactNode } from 'react';
import { Form, Formik } from 'formik';
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { TransactionFormBody } from './TransactionFormBody';
import {
  TransactionFormValues,
  transactionFormValuesToInput,
  TransactionModelInput,
  transactionValidationSchema,
} from '../models';
import { AccountModel } from '../../accounts/models';

interface TransactionFormProps {
  title: ReactNode;
  description: ReactNode;
  onClose: () => void;
  onSubmit: (input: TransactionModelInput) => void;
  initialValues: TransactionFormValues;
  accounts: AccountModel[];
  loading?: boolean;
}

export const TransactionForm = ({
  title,
  description,
  onClose,
  onSubmit,
  initialValues,
  accounts,
  loading,
}: TransactionFormProps): ReactElement => {
  const handleSubmit = (formValues: TransactionFormValues) => {
    const variables = transactionFormValuesToInput(formValues);
    return onSubmit(variables);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={transactionValidationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <DialogTitle>
          <Typography fontWeight="medium">{title}</Typography>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {description}
          </Typography>
        </DialogTitle>
        <DialogContent dividers sx={{ bgcolor: 'white' }}>
          <TransactionFormBody accounts={accounts} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton loading={loading} type="submit">
            Save
          </LoadingButton>
        </DialogActions>
      </Form>
    </Formik>
  );
};
