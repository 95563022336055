import React, { ReactElement, useState } from 'react';
import {
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  ButtonProps,
  Chip,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  IconButtonProps,
  styled,
  Typography,
  FormControl,
} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MuiYearPicker from '@mui/lab/YearPicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { addMonths, format, startOfMonth, startOfYear, subMonths, subYears } from 'date-fns';
import { MonthPicker } from './MonthPicker';
import { Pane } from '../Pane';

interface YearMonthPickerProps {
  value: Date;
  onChange: (nextDate: Date) => void;
}

enum View {
  UNINITIALIZED = 'UNINITIALIZED',
  YEAR_PICKED = 'YEAR_PICKED',
  MONTH_PICKED = 'MONTH_PICKED',
}

const isInitialStep = (view: View) => view === View.UNINITIALIZED;
const isYearPicked = (view: View) => view === View.YEAR_PICKED;
const isMonthPicked = (view: View) => view === View.MONTH_PICKED;

const SafeIconButton = ({
  fullWidth: _fullWidth,
  disableElevation: _disableElevation,
  ...props
}: IconButtonProps & ButtonProps): ReactElement => <IconButton {...props} />;

const HeaderButton = styled(Button)(({ theme }) => ({
  ...theme.typography.subtitle1,
  color: theme.palette.text.primary,
}));

export const YearMonthPicker = ({ value, onChange }: YearMonthPickerProps): ReactElement => {
  const maxDate = addMonths(startOfMonth(new Date()), 1);
  const minDate = startOfYear(subYears(maxDate, 11));
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [date, setDate] = useState<Date>(startOfMonth(new Date()));
  const [view, setView] = useState(View.UNINITIALIZED);
  const label = format(value, 'LLL yyyy');

  const handleEdit = ({ currentTarget }: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (nextDate: Date | null) => {
    if (!nextDate) {
      return;
    }
    if (isInitialStep(view)) {
      setView(View.YEAR_PICKED);
    } else if (isYearPicked(view)) {
      setView(View.MONTH_PICKED);
    }
    setDate(nextDate);
  };

  const handleNext = () => {
    const nextMonth = addMonths(value, 1);
    onChange(nextMonth);
  };

  const handlePrev = () => {
    const prevMonth = subMonths(value, 1);
    onChange(prevMonth);
  };

  const handleSubmit = () => {
    onChange(date);
    handleClose();
  };

  const title = (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="Pick month and year"
      sx={{ '& .MuiBreadcrumbs-ol': { justifyContent: 'center', height: '32px' } }}
    >
      {isInitialStep(view) ? (
        <Typography variant="subtitle1" sx={{ padding: '4px 5px' }}>
          Select a year
        </Typography>
      ) : (
        <HeaderButton onClick={() => setView(View.UNINITIALIZED)} size="small">
          {format(date, 'yyyy')}
        </HeaderButton>
      )}
      {isYearPicked(view) ? (
        <Typography variant="subtitle1" sx={{ padding: '4px 5px' }}>
          Select a month
        </Typography>
      ) : (
        isMonthPicked(view) && (
          <HeaderButton onClick={() => setView(View.YEAR_PICKED)}>
            {format(date, 'LLLL')}
          </HeaderButton>
        )
      )}
    </Breadcrumbs>
  );

  return (
    <>
      <FormControl sx={{ width: 'auto' }}>
        <Chip
          variant="outlined"
          color="primary"
          sx={{
            '& .MuiChip-label': { padding: 0 },
          }}
          label={
            <ButtonGroup color="primary" size="small" variant="text">
              <SafeIconButton
                size="small"
                color="primary"
                onClick={handlePrev}
                aria-label="Previous month"
              >
                <NavigateBeforeIcon />
              </SafeIconButton>
              <Button
                color="primary"
                onClick={handleEdit}
                sx={{ paddingLeft: (theme) => theme.spacing(2) }}
              >
                <Typography variant="button">{label}</Typography>{' '}
                {anchorEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>
              <SafeIconButton
                size="small"
                color="primary"
                onClick={handleNext}
                aria-label="Next month"
              >
                <NavigateNextIcon />
              </SafeIconButton>
            </ButtonGroup>
          }
        />
      </FormControl>
      <Pane anchorEl={anchorEl} onClose={handleClose} onClosed={() => setView(View.UNINITIALIZED)}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ width: '330px' }}>
          <div>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {view === View.UNINITIALIZED ? (
                <MuiYearPicker
                  date={date}
                  isDateDisabled={() => false}
                  onChange={handleChange}
                  minDate={minDate}
                  maxDate={maxDate}
                />
              ) : (
                <MonthPicker date={date} onChange={handleChange} />
              )}
            </LocalizationProvider>
          </div>
        </DialogContent>
        <DialogActions>
          <Box sx={{ marginLeft: 'auto' }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit} disabled={!isMonthPicked(view)}>
              Confirm
            </Button>
          </Box>
        </DialogActions>
      </Pane>
    </>
  );
};
