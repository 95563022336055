import React, { ComponentType, PropsWithChildren, ReactElement, useState } from 'react';
import TreeItem from '@mui/lab/TreeItem';
import { ListItemIcon, ListItemText, Menu, MenuItem, SvgIconProps } from '@mui/material';
import { LongPressEvent, useLongPress } from 'use-long-press';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { CategoryListItem } from './CategoryListItem';

interface MenuPosition {
  top: number;
  left: number;
}

const isTouchEvent = (e: LongPressEvent): e is React.TouchEvent =>
  Object.prototype.hasOwnProperty.call(e, 'touches');

interface CategoryTreeItemProps {
  id: string;
  name: string;
  color: string;
  icon?: ComponentType<SvgIconProps>;
  onDelete?: (id: string) => void;
  onEdit?: (id: string) => void;
}

export const CategoryTreeItem = ({
  id,
  name,
  color,
  children,
  icon,
  onEdit,
  onDelete,
}: PropsWithChildren<CategoryTreeItemProps>): ReactElement => {
  const [menuPosition, setMenuPosition] = useState<MenuPosition | null>(null);
  const withMenu = onEdit || onDelete;
  const handleClose = () => {
    setMenuPosition(null);
  };

  const handleLongPress = (event: LongPressEvent | undefined) => {
    if (!event) {
      return;
    }
    const position = isTouchEvent(event)
      ? {
          top: event.touches[0].clientY,
          left: event.touches[0].clientX,
        }
      : {
          top: event.clientY,
          left: event.clientX,
        };
    setMenuPosition(position);
  };

  const handleEdit = () => {
    if (onEdit) {
      onEdit(id);
    }
    handleClose();
  };

  const handleDelete = () => {
    if (onDelete) {
      onDelete(id);
    }
    handleClose();
  };

  const bind = useLongPress(handleLongPress, {
    captureEvent: true,
  });

  return (
    <>
      <TreeItem
        nodeId={id}
        label={
          <CategoryListItem
            id={id}
            name={name}
            color={color}
            icon={icon}
            listItemProps={withMenu ? bind : undefined}
          />
        }
      >
        {children}
      </TreeItem>
      {withMenu && (
        <Menu
          anchorReference="anchorPosition"
          anchorPosition={menuPosition || undefined}
          open={!!menuPosition}
          onClose={handleClose}
        >
          {onEdit && (
            <MenuItem onClick={handleEdit}>
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Edit category</ListItemText>
            </MenuItem>
          )}
          {onDelete && (
            <MenuItem onClick={handleDelete}>
              <ListItemIcon>
                <DeleteIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Delete</ListItemText>
            </MenuItem>
          )}
        </Menu>
      )}
    </>
  );
};
