import { colors } from '@mui/material';

type ColorPalette = Record<string | number, string>;

export const colorsByMain = Object.values(colors).reduce<Record<string, string[]>>(
  (prev, curr: ColorPalette) => {
    const main = curr[500];
    return main ? { ...prev, [main]: Object.values(curr) } : prev;
  },
  {},
);

export const mainColors = Object.keys(colorsByMain);

export const findMainColor = (color: string): string => {
  if (mainColors.includes(color)) {
    return color;
  }
  const match = Object.entries(colorsByMain).find(([, derived]) => derived.includes(color));
  return match ? match[0] : mainColors[4];
};
